import { useQuery, useQueryClient } from 'react-query';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import LoadingWrapper from 'components/LoadingWrapper';
import LoginPage from 'features/auth/LoginPage';
import { useAuth } from 'providers/AuthProvider';
import api from 'utils/api';

const ALLOWED_ROUTES = ['preview/survey', 'shared/collection', 'shared/survey'];

const HiveRoute = () => {
  const { authenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams();

  const queryClient = useQueryClient();

  const { status, isSuccess } = useQuery(
    ['hive', slug],
    async () => {
      const { data } = await api.get(`/public/hives/${slug}`);
      return data;
    },
    {
      onSuccess: () => {
        localStorage.setItem('slug', slug);
      },
      onError: () => {
        localStorage.removeItem('slug');
        if (authenticated) {
          navigate('/join');
        } else {
          navigate('/');
        }
      }
    }
  );

  queryClient.setQueryDefaults(['hive', slug], {
    queryFn: async () => {
      const { data } = await api.get(`/public/hives/${slug}`);
      return data;
    }
  });

  const allowedRoute = ALLOWED_ROUTES.some(value =>
    location.pathname.includes(value)
  );

  return (
    <LoadingWrapper statuses={[status]}>
      {/* {isSuccess && <Outlet />} */}
      {isSuccess ? (
        authenticated || allowedRoute ? (
          <Outlet />
        ) : (
          <LoginPage />
        )
      ) : null}
    </LoadingWrapper>
  );
};

export default HiveRoute;
