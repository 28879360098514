import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react';
import CardFields from './CardFields';
import { getStepFields } from './cardUtils';
import MembersButton from 'components/MembersButton';
import api from 'utils/api';
import MembersModal from 'components/modals/MembersModal';

const CardStep = ({
  card,
  filter,
  noEmptyState = false,
  forReport = false,
  updateCard
}) => {
  const {
    isOpen: showPanelModal,
    onOpen: openPanelModal,
    onClose: closePanelModal
  } = useDisclosure();

  const [isMobile] = useMediaQuery('(max-width: 62em)');
  const { t } = useTranslation();
  const { data: me } = useQuery('me');

  const fields = getStepFields(card, filter, me);

  const queryClient = useQueryClient();

  const { data: panel, isSuccess: panelIsSuccess } = useQuery(
    ['panel', card.id, filter?.step],
    async () => {
      const params = new URLSearchParams({
        card: card.id
      });
      if (filter?.step) {
        params.append('step', filter.step);
      } else {
        params.append('step__isnull', 'true');
      }
      const { data } = await api.get(`/panels?${params.toString()}`);
      if (data.length > 0) {
        return data[0];
      }
      return null;
    }
  );

  const createPanelMutation = useMutation(panel => api.post('/panels', panel), {
    onSuccess: ({ data: panel }) => {
      queryClient.setQueryData(['panel', card.id, filter?.step], panel);
    }
  });

  const deletePanelMutation = useMutation(
    () => api.delete(`/panels/${panel?.id}`),
    {
      onSuccess: () => {
        queryClient.setQueryData(['panel', card.id, filter?.step], null);
      }
    }
  );

  const updatePanelMutation = useMutation(
    panel => api.patch(`/panels/${panel?.id}`, panel),
    {
      onSuccess: ({ data: panel }) => {
        queryClient.setQueryData(['panel', card.id, filter?.step], panel);
      }
    }
  );

  const handleOnChangePanel = async ({ members }) => {
    if (members.length === 0) {
      if (panel) {
        await deletePanelMutation.mutateAsync();
      }
    } else {
      const ids = members.map(m => m.id);
      if (panel) {
        await updatePanelMutation.mutateAsync({
          ...panel,
          members: ids
        });
      } else {
        await createPanelMutation.mutateAsync({
          card: card.id,
          step: filter?.step ? filter.step : null,
          workflow: card.collection?.workflow?.id,
          members: ids
        });
      }
    }
  };

  return (
    <>
      <Stack spacing={8} ml={isMobile || filter.step__isnull ? 0 : 8} my={2}>
        {!forReport &&
          panelIsSuccess &&
          (panel?.members?.length > 0 ? (
            <HStack>
              <Text variant="muted">{t('common.panel')}</Text>
              <MembersButton
                max={3}
                members={panel?.members ? panel.members : []}
                onSubmit={handleOnChangePanel}
                canEdit
              />
            </HStack>
          ) : (
            <Button
              alignSelf="flex-start"
              variant="link"
              size="xs"
              onClick={openPanelModal}
            >
              {t('card.add_panel')}
            </Button>
          ))}
        {fields.length > 0 ? (
          <CardFields
            card={card}
            fields={fields}
            panel={panel}
            forReport={forReport}
            lastField={fields[fields.length - 1]}
            panelMember={panel?.members.some(m => m.id === me?.id)}
            updateCard={updateCard}
          />
        ) : noEmptyState ? null : (
          <Text variant="muted">
            {t('workflow.no_fields_or_charts_empty_state')}
          </Text>
        )}
      </Stack>
      <MembersModal
        members={panel?.members ? panel.members : []}
        isOpen={showPanelModal}
        onClose={closePanelModal}
        onSubmit={handleOnChangePanel}
        canEdit
      />
    </>
  );
};

export default CardStep;
