import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  AvatarGroup,
  Box,
  Circle,
  Heading,
  HStack,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react';
import MemberAvatar from 'components/MemberAvatar';
import ItemWrapper from 'components/ItemWrapper';
import { useRef } from 'react';

const ProjectListItem = ({ project }) => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const ref = useRef();

  return (
    <ItemWrapper
      ref={ref}
      to={`/${slug}/project/${project.id}`}
      justifyContent="space-between"
      height="full"
    >
      <Stack spacing={4}>
        <Heading fontSize="md">{project.title}</Heading>
        <HStack>
          <VStack>
            <Text variant="muted">{t('common.owner')}</Text>
            <MemberAvatar link={false} member={project.owner} size="sm" />
          </VStack>
          <Stack>
            <Text variant="muted">{t('common.members')}</Text>
            {project.members.length > 0 ? (
              <AvatarGroup size="sm" max={5}>
                {project.members.map(member => (
                  <MemberAvatar key={member.id} link={false} member={member} />
                ))}
              </AvatarGroup>
            ) : (
              <Circle justifyContent="start" size={8}>
                <Text>-</Text>
              </Circle>
            )}
          </Stack>
        </HStack>
        <Box>
          <Text fontSize="xs" textTransform="lowercase">
            {t('project.number_collections_in_this_project', {
              number: project.metrics.collections.all
            })}
          </Text>
        </Box>
      </Stack>
    </ItemWrapper>
  );
};

export default ProjectListItem;
