import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Flex,
  Heading,
  Stack,
  useToast,
  useDisclosure,
  HStack
} from '@chakra-ui/react';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import {
  ActionMenu,
  CustomMenuItem,
  DeleteMenuItem,
  EditMenuItem
} from 'components/ActionMenu';
import BackButton from 'components/BackButton';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import IconTooltip from 'components/tooltips/IconTooltip';
import api from 'utils/api';
import StepList from './StepList';
import WorkflowForm from './WorkflowForm';
import WorkflowSettings from './WorkflowSettings';

const WorkflowDetailed = () => {
  const {
    isOpen: showDeleteWorkflowModal,
    onClose: closeDeleteWorkflowModal,
    onOpen: openDeleteWorkflowModal
  } = useDisclosure();

  const {
    isOpen: showUpdateWorkflowModal,
    onClose: closeUpdateWorkflowModal,
    onOpen: openUpdateWorkflowModal
  } = useDisclosure();

  const {
    isOpen: showWorkflowSettingsModal,
    onClose: closeWorkflowSettingsModal,
    onOpen: openWorkflowSettingsModal
  } = useDisclosure();

  const { slug, workflowId } = useParams();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const toast = useToast();
  const { t } = useTranslation();

  const {
    status,
    data: workflow,
    isSuccess
  } = useQuery(['workflow', workflowId], async () => {
    const { data } = await api.get(`/workflows/${workflowId}`);
    return data;
  });

  const deleteWorkflowMutation = useMutation(
    async () => {
      await api.delete(`/workflows/${workflowId}`);
    },
    {
      onSuccess: () => {
        toast({
          title: t('toast.delete_success', {
            entity: t('common.workflow')
          }),
          status: 'success'
        });
        navigate(`/${slug}/workflows`);
      },
      onError: () => {
        toast({
          title: t('toast.delete_error', {
            entity: t('common.workflow')
          }),
          status: 'error'
        });
      }
    }
  );

  const updateWorkflowMutation = useMutation(
    async workflow => await api.patch(`/workflows/${workflowId}`, workflow),
    {
      onSuccess: ({ data: workflow }) => {
        const workflows = queryClient.getQueryData('workflows');
        if (workflows) {
          queryClient.setQueryData(
            'workflows',
            workflows.map(m => (m.id === workflowId ? workflow : m))
          );
        }
        queryClient.setQueryData(['workflow', workflowId], workflow);
        toast({
          title: t('toast.update_success', {
            entity: t('common.workflow')
          }),
          status: 'success'
        });
      },
      onError: () => {
        toast({
          title: t('toast.update_error', {
            entity: t('common.workflow')
          }),
          status: 'error'
        });
      }
    }
  );

  const handleOnDeleteWorkflow = async () => {
    await deleteWorkflowMutation.mutateAsync();
    closeDeleteWorkflowModal();
  };

  const handleOnUpdateWorkflow = async workflow => {
    await updateWorkflowMutation.mutateAsync({
      contribute_button_label: workflow.contribute_button_label,
      name: workflow.name,
      type: workflow.type.id,
      reaction_type: workflow.reaction_type
    });
    closeUpdateWorkflowModal();
  };

  const handleOnUpdateWorkflowFormOrSettings = async payload => {
    await updateWorkflowMutation.mutateAsync(payload);
  };

  return (
    <Container maxW="container.lg" marginY={8}>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.workflow').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack spacing={4}>
            <Flex
              minWidth="full"
              alignItems="center"
              justifyContent="space-between"
            >
              <HStack alignItems="center" spacing={2}>
                <Heading fontSize="2xl">{workflow.name}</Heading>
                <IconTooltip
                  label={t('workflow.auto_save_tooltip')}
                  color="gray"
                  alignSelf="center"
                />
              </HStack>
              <ActionMenu>
                <EditMenuItem onClick={openUpdateWorkflowModal} />
                <DeleteMenuItem onClick={openDeleteWorkflowModal} />
                <CustomMenuItem
                  onClick={openWorkflowSettingsModal}
                  icon={faCog}
                  label={t('common.settings')}
                />
              </ActionMenu>
            </Flex>
            <BackButton />
            <StepList
              workflow={workflow}
              onUpdateWorkflow={handleOnUpdateWorkflowFormOrSettings}
            />
            <ConfirmationModal
              deleteText={t(
                'confirmation.this_information_will_be_permanently_removed_and_cannot_be_restored'
              )}
              isOpen={showDeleteWorkflowModal}
              onClose={closeDeleteWorkflowModal}
              onDelete={handleOnDeleteWorkflow}
            />
            <ModalWrapper
              title={t('common.workflow')}
              isOpen={showUpdateWorkflowModal}
              onClose={closeUpdateWorkflowModal}
            >
              <WorkflowForm
                defaultValues={{
                  name: workflow.name,
                  type: workflow.type,
                  contribute_button_label: workflow.contribute_button_label,
                  reaction_type: workflow.reaction_type
                }}
                isOpen={showUpdateWorkflowModal}
                onSubmit={handleOnUpdateWorkflow}
              />
            </ModalWrapper>
            <ModalWrapper
              title={t('common.settings')}
              isOpen={showWorkflowSettingsModal}
              onClose={closeWorkflowSettingsModal}
            >
              <WorkflowSettings
                workflow={workflow}
                onUpdateWorkflow={handleOnUpdateWorkflowFormOrSettings}
                status={status}
              />
            </ModalWrapper>
          </Stack>
        ) : null}
      </LoadingWrapper>
    </Container>
  );
};

export default WorkflowDetailed;
