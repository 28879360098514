import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import AccessForm from 'components/AccessForm';
import BackButton from 'components/BackButton';
import CollectionList from 'features/collection/CollectionList';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import CoverMedia from 'components/CoverMedia';
import LoadingWrapper from 'components/LoadingWrapper';
import HeadingButton from 'components/HeadingButton';
import ModalWrapper from 'components/ModalWrapper';
import TitleDescription from 'components/TitleDescription';
import {
  ActionMenu,
  EditMenuItem,
  DeleteMenuItem
} from 'components/ActionMenu';
import CollectionCreationForm from 'features/collection/CollectionCreationForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import api from 'utils/api';

const BoardDetailed = () => {
  const [newCollection, setNewCollection] = useState(null);

  const {
    isOpen: showCreateCollectionModal,
    onOpen: openCreateCollectionModal,
    onClose: closeCreateCollectionModal
  } = useDisclosure();

  const {
    isOpen: showDeleteBoardModal,
    onOpen: openDeleteBoardModal,
    onClose: closeDeleteBoardModal
  } = useDisclosure();

  const {
    isOpen: showUpdateBoardModal,
    onOpen: openUpdateBoardModal,
    onClose: closeUpdateBoardModal
  } = useDisclosure();

  const navigate = useNavigate();
  const { slug, boardId } = useParams();
  const { data: profile } = useQuery(['profile', slug]);
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();

  const {
    status,
    data: board,
    isSuccess
  } = useQuery(['board', boardId], async () => {
    const { data } = await api.get(`/boards/${boardId}`);
    return data;
  });

  const deleteBoardMutation = useMutation(
    () => api.delete(`/boards/${boardId}`),
    {
      onSuccess: () => {
        closeDeleteBoardModal();
        toast({
          title: t('toast.delete_success', {
            entity: t('common.board')
          }),
          status: 'success'
        });
        navigate(-1);
      }
    }
  );

  const updateBoardMutation = useMutation(
    board => api.patch(`/boards/${boardId}`, board, { timeout: 0 }),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(['board', boardId], data);
      }
    }
  );

  const handleOnUpdateBoard = async board => {
    const payload = new FormData();
    if (!board.image || board.image instanceof File) {
      payload.append('image', board.image ? board.image : '');
    }
    if (!board.video || board.video instanceof File) {
      payload.append('video', board.video ? board.video : '');
    }
    payload.append('unsplash', board.unsplash ? board.unsplash : '');
    payload.append('title', board.title);
    payload.append('description', board.description ? board.description : '');
    payload.append('access', board.access.id);
    try {
      await updateBoardMutation.mutateAsync(payload);
      await updateBoardMutation.mutateAsync({
        domains: board.domains.map(domain => domain.id),
        groups: board.groups.map(group => group.id),
        members: board.members.map(member => member.id)
      });
      closeUpdateBoardModal();
      toast({
        title: t('toast.update_success', {
          entity: t('common.board')
        }),
        status: 'success'
      });
    } catch (e) {
      toast({
        title: t('toast.update_error', {
          entity: t('common.board')
        }),
        status: 'error'
      });
    }
  };

  const handleOnClickDelete = async () => {
    await deleteBoardMutation.mutateAsync();
  };

  const handleOnCloseCreateCollectionModal = () => {
    closeCreateCollectionModal();
    if (newCollection) {
      navigate(`/${slug}/collection/${newCollection.id}`);
    }
  };

  const hasCreateCollectionsPermission = profile?.is_admin_or_manager;

  return (
    <Container maxW="container.lg" marginY={8}>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.board').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack spacing={8}>
            <BackButton />
            <CoverMedia object={board} height="280px" clickToFullscreen />
            <TitleDescription
              title={board.title}
              description={board.description}
              button={
                profile?.is_admin_or_manager && (
                  <ActionMenu>
                    <EditMenuItem onClick={openUpdateBoardModal} />
                    <DeleteMenuItem onClick={openDeleteBoardModal} />
                  </ActionMenu>
                )
              }
            />
            <Divider />
            <HeadingButton
              title={t('collection.ongoing_collections')}
              fontSize="lg"
              button={
                hasCreateCollectionsPermission && (
                  <Flex width={['full', null, 'initial']} justify="flex-end">
                    <Button
                      colorScheme="teal"
                      onClick={openCreateCollectionModal}
                    >
                      <HStack>
                        <FontAwesomeIcon icon={faPlus} />
                        <Text>{t('common.collection')}</Text>
                      </HStack>
                    </Button>
                  </Flex>
                )
              }
            />
            <CollectionList boardId={boardId} />
            <ModalWrapper
              size="full"
              isOpen={showCreateCollectionModal}
              onClose={handleOnCloseCreateCollectionModal}
              hasSteps
            >
              <CollectionCreationForm
                isOpen={showCreateCollectionModal}
                onClose={handleOnCloseCreateCollectionModal}
                board={board}
                onCollectionCreated={newCollection => {
                  setNewCollection(newCollection);
                }}
                newCollection={newCollection}
              />
            </ModalWrapper>
            <ModalWrapper
              title={t('common.board')}
              isOpen={showUpdateBoardModal}
              onClose={closeUpdateBoardModal}
            >
              <AccessForm
                defaultValues={board}
                isOpen={showUpdateBoardModal}
                onSubmit={handleOnUpdateBoard}
                showImageSelect
              />
            </ModalWrapper>
            <ConfirmationModal
              deleteText={t(
                'confirmation.this_information_will_be_permanently_removed_and_cannot_be_restored'
              )}
              isOpen={showDeleteBoardModal}
              onClose={closeDeleteBoardModal}
              onDelete={handleOnClickDelete}
            />
          </Stack>
        ) : null}
      </LoadingWrapper>
    </Container>
  );
};

export default BoardDetailed;
