import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AspectRatio,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useToast
} from '@chakra-ui/react';
import GridAttachmentList from 'features/attachment/GridAttachmentList';
import ButtonAnimator from 'components/ButtonAnimator';
import CollectionSearchFilter from './CollectionSearchFilter';
import CoverMedia from 'components/CoverMedia';
import LoadingWrapper from 'components/LoadingWrapper';
import LoginModal from 'components/modals/LoginModal';
import ModalWrapper from 'components/ModalWrapper';
import TitleDescription from 'components/TitleDescription';
import PoweredByHives from 'components/PoweredByHives';
import CardForm from 'features/card/CardForm';
import CardList from 'features/card/CardList';
import { useUi } from 'providers/UiProvider';
import { getCollectingPlural, getCollectingSingular } from './collectionUtils';
import GuestHeader from 'features/navigation/GuestHeader';
import LoginHeader from 'features/navigation/LoginHeader';
import { useConfetti } from 'providers/ConfettiProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import api from 'utils/api';
import CollectionCardFilter from './CollectionCardFilter';
import {
  cardFilterToArray,
  generateCardPayload
} from 'features/card/cardUtils';

const InvitedCollectionDetailed = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [showFilter, setShowFilter] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showCreateCardModal, setShowCreateCardModal] = useState(false);
  const { fire } = useConfetti();
  const toast = useToast();

  const { collectionId } = useParams();
  const { filter, setFilter } = useUi();

  useEffect(() => {
    if (Object.keys(filter).length < 1) {
      setFilter({
        ...filter,
        collection: collectionId
      });
    }
  }, [setFilter, filter, collectionId]);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    data: collection,
    status,
    isSuccess
  } = useQuery(
    ['collection', collectionId],
    async () => {
      const { data } = await api.get(
        `/invited/collections/${collectionId}?hive__slug=${slug}`
      );
      return data;
    },
    {
      onError: error => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 404
        ) {
          navigate(
            `/${slug}/login?from=/${slug}/invited/collection/${collectionId}`
          );
        }
      }
    }
  );

  const createCardMutation = useMutation(
    card => api.post(`/cards?hive=${slug}`, card, { timeout: 0 }),
    {
      onSuccess: ({ data: card }) => {
        const cards = queryClient.getQueryData([
          'cards',
          { hive: slug },
          ...cardFilterToArray({ ...filter })
        ]);
        const pages = cards.pages.map(page => {
          return {
            ...page,
            count: page.count + 1
          };
        });
        if (pages.length > 0) {
          pages[0].results = [card, ...pages[0].results];
        }
        queryClient.setQueryData(
          ['cards', { hive: slug }, ...cardFilterToArray({ ...filter })],
          {
            ...cards,
            pages
          }
        );
        toggleShowCreateCardModal();
        fire();
        toast({
          title: t('card.contribution_thank_you'),
          status: 'success'
        });
      }
    }
  );

  const toggleShowLoginModal = () => {
    setShowLoginModal(!showLoginModal);
  };

  const toggleShowCreateCardModal = () => {
    setShowCreateCardModal(!showCreateCardModal);
  };

  const handleOnClickCreateCard = () => {
    if (collection.is_authenticated) {
      toggleShowCreateCardModal();
    } else {
      toggleShowLoginModal();
    }
  };

  const handleOnCreateCard = async card => {
    await createCardMutation.mutateAsync(
      generateCardPayload(collectionId, card)
    );
  };

  return (
    <Flex flexDirection="column">
      {status === 'success' ? (
        collection.is_authenticated ? (
          <GuestHeader
            darkLogo={collection.hive.dark_logo}
            lightLogo={collection.hive.light_logo}
          />
        ) : (
          <LoginHeader
            darkLogo={collection.hive.dark_logo}
            lightLogo={collection.hive.light_logo}
            toggleShowLoginModal={toggleShowLoginModal}
          />
        )
      ) : null}
      <Container marginY={8} maxW="container.lg">
        <LoadingWrapper
          statuses={[status]}
          errorMessages={[
            t('common.could_not_fetch_data_please_try_again_later', {
              data: t('common.collection').toLowerCase()
            })
          ]}
        >
          {isSuccess ? (
            <>
              <Stack spacing={8}>
                <PoweredByHives />
                <SimpleGrid columns={[1, null, 2]} spacing={8}>
                  <AspectRatio ratio={16 / 9}>
                    <CoverMedia object={collection} clickToFullscreen />
                  </AspectRatio>
                  <Stack
                    spacing={8}
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <TitleDescription
                      title={collection?.title}
                      description={collection?.description}
                      noOfLines={5}
                    />
                    <Flex
                      justifyContent={['flex-end', null, 'flex-start']}
                      alignSelf="flex-end"
                    >
                      <Tooltip
                        isDisabled={collection.open}
                        label={t('collection.closed_collection_tooltip')}
                        shouldWrapChildren
                      >
                        <ButtonAnimator
                          active
                          width={['full', 'min-content', 'min-content']}
                        >
                          <Button
                            colorScheme="teal"
                            onClick={handleOnClickCreateCard}
                          >
                            <HStack>
                              <FontAwesomeIcon icon={faPlus} />
                              <Text>
                                {getCollectingSingular(collection.type)}
                              </Text>
                            </HStack>
                          </Button>
                        </ButtonAnimator>
                      </Tooltip>
                    </Flex>
                  </Stack>
                </SimpleGrid>
                <GridAttachmentList
                  modelConfig={{
                    singular: 'collection',
                    plural: 'collections',
                    modelId: collection.id
                  }}
                  canEdit={false}
                />
                <Flex alignItems="center" justifyContent="space-between">
                  <Heading fontSize="xl">
                    {getCollectingPlural(collection.type)}
                  </Heading>
                </Flex>
                <CollectionSearchFilter
                  collection={collection}
                  filter={filter}
                  setFilter={setFilter}
                  showFilter={showFilter}
                  toggleFilter={() => {
                    setShowFilter(!showFilter);
                  }}
                />
                {showFilter && (
                  <CollectionCardFilter
                    collection={collection}
                    filter={filter}
                    setFilter={setFilter}
                  />
                )}
                <CardList filter={filter} />
              </Stack>
              {collection.is_authenticated && (
                <ModalWrapper
                  title={getCollectingSingular(collection.type)}
                  size="full"
                  isOpen={showCreateCardModal}
                  onClose={toggleShowCreateCardModal}
                >
                  <CardForm
                    titleOverride={collection.workflow?.title_override}
                    useDescription={collection.workflow?.use_card_description}
                    useImage={collection.workflow?.use_card_image}
                    collection={collection}
                    isOpen={showCreateCardModal}
                    onSubmit={handleOnCreateCard}
                  />
                </ModalWrapper>
              )}
            </>
          ) : null}
        </LoadingWrapper>
      </Container>
      <LoginModal isOpen={showLoginModal} onClose={toggleShowLoginModal} />
    </Flex>
  );
};

export default InvitedCollectionDetailed;
