import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Show,
  Spacer,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import CoverMedia from 'components/CoverMedia';
import LoadingWrapper from 'components/LoadingWrapper';
import PoweredByHives from 'components/PoweredByHives';
import { useConfetti } from 'providers/ConfettiProvider';
import api from 'utils/api';
import SurveyFields from './SurveyFields';
import TitleDescription from 'components/TitleDescription';
import { motion, useSpring } from 'framer-motion';

const HeaderNavigation = ({ slug, logo, ...rest }) => (
  <HStack {...rest}>
    <Link to={`/${slug}`}>
      <Image src={logo} height={8} />
    </Link>
  </HStack>
);

const CountdownProgress = ({ max, value, labelCount, text, size }) => {
  const labelLimit = max / 10;
  return (
    <>
      {text}
      <Flex width="fit-content" justifyContent="center">
        <CircularProgress
          size={size}
          thickness={4}
          as={motion.div}
          value={value}
          max={max}
        >
          <CircularProgressLabel>
            {labelCount >= 0
              ? labelCount <= labelLimit
                ? labelCount
                : labelLimit
              : 0}
          </CircularProgressLabel>
        </CircularProgress>
      </Flex>
    </>
  );
};

const SharedSurveyDetailed = () => {
  const [fieldIndex, setFieldIndex] = useState(0);
  const [countdown, setCountdown] = useState(20);
  const [inactive, setInactive] = useState(false);
  const timeoutRef = useRef(null);
  const springValue = useSpring(countdown * 10, {
    stiffness: 100,
    damping: 20
  }); // needed to correctly finish the completion timer reaching 0, but has no effect on the modal one, probably due to modal closing
  const { fire } = useConfetti();
  const { code, slug } = useParams();

  const { t } = useTranslation();

  const [introduction, setIntroduction] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const queryClient = useQueryClient();

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setInactive(true);
    }, 30 * 1000);
  };

  useEffect(() => {
    const handleUserInteraction = () => {
      resetTimeout();
    };

    if (!introduction) {
      window.addEventListener('mousemove', handleUserInteraction);
      window.addEventListener('keydown', handleUserInteraction);
      window.addEventListener('click', handleUserInteraction);

      resetTimeout();

      return () => {
        window.removeEventListener('mousemove', handleUserInteraction);
        window.removeEventListener('keydown', handleUserInteraction);
        window.removeEventListener('click', handleUserInteraction);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [t, introduction]);

  const findSurveySession = code => {
    try {
      const surveySessions =
        JSON.parse(localStorage.getItem('surveySessions')) || [];
      const session = surveySessions.find(session => session.survey === code);
      return session ? session.id : null;
    } catch (error) {
      console.error('Failed to retrieve survey session:', error);
      return null;
    }
  };

  const updateSurveySession = (code, sessionId) => {
    try {
      let surveySessions =
        JSON.parse(localStorage.getItem('surveySessions')) || [];
      const sessionExists = surveySessions.some(
        session => session.survey === code && session.id === sessionId
      );
      if (!sessionExists) {
        surveySessions.push({ survey: code, id: sessionId });
        localStorage.setItem('surveySessions', JSON.stringify(surveySessions));
        setSessionId(sessionId);
      }
    } catch (error) {
      console.error('Failed to update survey session:', error);
    }
  };

  const {
    status: surveyStatus,
    data: survey,
    isSuccess: surveyIsSuccess
  } = useQuery(['survey', code], async () => {
    const { data } = await api.get(`/shared/surveys/${code}`);
    return data;
  });

  const {
    status: surveyResponseStatus,
    data: surveyResponse,
    isSuccess: surveyResponseIsSuccess,
    refetch: refetchSurveyResponse
  } = useQuery(
    ['survey', 'response', code],
    async () => {
      const surveySession = findSurveySession(code);
      if (surveySession) {
        setSessionId(surveySession);
      }
      const { data } = await api.get(
        `/shared/surveys/${code}/response${
          surveySession ? `?session_id=${surveySession}` : ''
        }`
      );
      return data;
    },
    {
      onSuccess: data => {
        if (data.session_id) {
          updateSurveySession(code, data.session_id);
        }
      },
      enabled: surveyIsSuccess
    }
  );

  const createAnswerMutation = useMutation(
    payload =>
      api.post(
        sessionId
          ? `/surveys/responses/${surveyResponse.id}/answer?session_id=${sessionId}`
          : `/surveys/responses/${surveyResponse.id}/answer`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['survey', 'response', code]);
      },
      onError: () => {
        console.log('Error creating answer');
      }
    }
  );

  const createSelectionMutation = useMutation(
    payload =>
      api.post(
        sessionId
          ? `/surveys/responses/${surveyResponse.id}/selection?session_id=${sessionId}`
          : `/surveys/responses/${surveyResponse.id}/selection`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['survey', 'response', code]);
      },
      onError: () => {
        console.log('Error creating selection');
      }
    }
  );

  const handleOnClickPrevious = () => {
    if (fieldIndex > 0) {
      setFieldIndex(fieldIndex - 1);
      setMissingRequired(false);
    }
  };

  const goToNextField = (nextFieldId = null, isCompletion = false) => {
    const isLastField = fieldIndex === survey.fields.length - 1;
    if (isCompletion || isLastField) {
      setCountdown(10);
      setCompleted(true);
      return;
    }
    let nextFieldIndex = fieldIndex + 1;
    if (nextFieldId) {
      const specificFieldIndex = survey.fields.findIndex(
        field => field.id === nextFieldId
      );
      if (specificFieldIndex !== -1) {
        nextFieldIndex = specificFieldIndex;
      }
    }
    setFieldIndex(nextFieldIndex);
  };

  const onSubmitAnswer = async form => {
    const field = survey.fields[fieldIndex];
    await createAnswerMutation.mutateAsync({
      survey: survey.id,
      field: field.id,
      answer: form.answer
    });
    goToNextField();
  };

  const onSubmitMultiSelection = async form => {
    const field = survey.fields[fieldIndex];
    await createSelectionMutation.mutateAsync({
      field: field.id,
      options: form.options
    });
    goToNextField();
  };

  const onSubmitSingleSelection = async form => {
    const field = survey.fields[fieldIndex];
    const selectedOption = field.options.find(
      option => option.id === form.option
    );
    await createSelectionMutation.mutateAsync({
      field: field.id,
      options: [form.option]
    });
    goToNextField(selectedOption?.next_field, selectedOption?.is_completion);
  };

  useEffect(() => {
    if (completed) {
      fire();
    }
  }, [completed, fire]);

  const handleOnDismissCountdown = () => {
    setInactive(false);
    setCountdown(20);
    resetTimeout();
  };

  const handleOnFormReset = useCallback(() => {
    setCountdown(-1); //needed to not visually stay at 0
    setTimeout(() => {
      setFieldIndex(0);
      setCompleted(false);
      setCountdown(20);
      setIntroduction(true);
      setInactive(false);
    }, 750);
    if (survey?.kiosk_mode) {
      /* Remove survey sessions */
      const surveySessions = JSON.parse(localStorage.getItem('surveySessions'));
      const filteredSessions = surveySessions.filter(
        session => session.survey !== code
      );
      localStorage.setItem('surveySessions', JSON.stringify(filteredSessions));
      refetchSurveyResponse();
    }
  }, [code, survey, refetchSurveyResponse]);

  useEffect(() => {
    if (completed || inactive) {
      const interval = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown <= 0) {
            clearInterval(interval);
            handleOnFormReset();
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [completed, inactive, handleOnFormReset, countdown]);

  useEffect(() => {
    springValue.set(countdown * 10);
  }, [countdown, springValue]);

  const hive = queryClient.getQueryData(['hive', slug]);

  const logo = useColorModeValue(
    hive?.dark_logo ? hive.dark_logo : '/hives_dark.png',
    hive?.light_logo ? hive.light_logo : '/hives_light.png'
  );

  /*
    TODO: this is a bit of a gotcha. By using pointer events none,
    we're able to make sure that the next / done button is clickable but this
    solution should be refactored to prevent potential future bugs.
  */
  const FooterNavigation = ({ ...props }) => (
    <HStack {...props} pointerEvents="none">
      <Flex pointerEvents="auto">
        <PoweredByHives />
      </Flex>
      <Spacer />
      {!introduction && !completed && (
        <>
          <Text>{`${fieldIndex + 1} / ${survey?.fields.length}`}</Text>
          <HStack pointerEvents="auto">
            <Button
              variant="outline"
              isDisabled={fieldIndex === 0}
              onClick={handleOnClickPrevious}
            >
              <FontAwesomeIcon icon={faChevronUp} />
            </Button>
            <Button
              type="submit"
              form="survey-form"
              variant="outline"
              isLoading={
                createAnswerMutation.isLoading ||
                createSelectionMutation.isLoading
              }
              isDisabled={
                missingRequired || fieldIndex === survey?.fields.length - 1
              }
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </Button>
          </HStack>
        </>
      )}
    </HStack>
  );

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center">
      <Show above="lg">
        <HeaderNavigation
          slug={slug}
          logo={logo}
          position="absolute"
          minHeight="40px"
          top={8}
          left={8}
          right={8}
        />
      </Show>
      <Container maxW="container.xs" marginY={8}>
        <LoadingWrapper statuses={[surveyStatus, surveyResponseStatus]}>
          {surveyIsSuccess && surveyResponseIsSuccess && (
            <Stack spacing={8}>
              <Show below="lg">
                <HeaderNavigation slug={slug} logo={logo} />
              </Show>
              {introduction ? (
                <Stack spacing={8}>
                  <CoverMedia
                    object={survey}
                    height="200px"
                    hideIfEmpty
                    clickToFullscreen
                  />
                  <TitleDescription
                    title={survey.title}
                    description={survey.description}
                    noOfLines={5}
                  />
                  <Button
                    colorScheme="teal"
                    alignSelf="flex-end"
                    onClick={() => {
                      setIntroduction(false);
                    }}
                    isDisabled={survey.fields.length === 0}
                  >
                    {t('button.start')}
                  </Button>
                </Stack>
              ) : completed ? (
                <Stack spacing={8}>
                  <Heading fontSize="3xl">
                    {`${t('survey.completion.thank_you_title')} 🎉`}
                  </Heading>
                  <Text>{t('survey.completion.thank_you_description')}</Text>
                  <Spacer />
                  <HStack
                    spacing={4}
                    width="full"
                    justifyContent="space-between"
                  >
                    <HStack spacing={4} width="full" justifyContent="flex-end">
                      <CountdownProgress
                        text={
                          <Text textAlign="right" width="full">
                            {t('survey.restarting_in')}
                          </Text>
                        }
                        value={springValue?.current}
                        labelCount={Math.round(countdown + 0.5)}
                        max={100}
                        size="80px"
                      />
                    </HStack>
                    <Button
                      width="fit-content"
                      alignSelf="center"
                      variant="outline"
                      onClick={handleOnFormReset}
                    >
                      {t('survey.restart_now')}
                    </Button>
                  </HStack>
                </Stack>
              ) : (
                <Stack spacing={8}>
                  <SurveyFields
                    onSubmitAnswer={onSubmitAnswer}
                    onSubmitMultiSelection={onSubmitMultiSelection}
                    onSubmitSingleSelection={onSubmitSingleSelection}
                    slug={slug}
                    survey={survey}
                    fieldIndex={fieldIndex}
                    surveyResponse={survey?.kiosk_mode ? null : surveyResponse}
                    missingRequired={missingRequired}
                    setMissingRequired={setMissingRequired}
                  />
                </Stack>
              )}
              <Show below="lg">
                <FooterNavigation />
              </Show>
            </Stack>
          )}
          <Show above="lg">
            <FooterNavigation
              position="absolute"
              minHeight="40px"
              bottom={8}
              left={8}
              right={8}
              spacing={4}
            />
          </Show>
          <Modal
            isOpen={inactive && !completed}
            onClose={handleOnDismissCountdown}
            size="md"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader>
                <Heading size="lg">{t('survey.still_there')}</Heading>{' '}
              </ModalHeader>
              <ModalBody>
                <Stack spacing={4} width="full" alignItems="center">
                  <CountdownProgress
                    value={springValue?.current}
                    text={
                      <Text textAlign="center" width="66%">
                        {t('survey.clear_answers_warning')}
                      </Text>
                    }
                    labelCount={Math.round(countdown + 0.5)}
                    max={200}
                    size="100px"
                  />
                </Stack>
              </ModalBody>
              <ModalFooter>
                <HStack width="full" justifyContent="space-between" spacing={4}>
                  <Button variant="outline" onClick={handleOnFormReset}>
                    {t('survey.restart_now')}
                  </Button>
                  <Button colorScheme="teal" onClick={handleOnDismissCountdown}>
                    {t('button.continue')}
                  </Button>
                </HStack>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </LoadingWrapper>
      </Container>
    </Flex>
  );
};

export default SharedSurveyDetailed;
