import {
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';

const ImageDetailed = ({ src, isOpen, onClose }) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="fit-content" bg="transparent">
          <Image src={src} maxH="80vh" maxW="80vw" objectFit="contain" />
          <ModalCloseButton
            color="white"
            bg="blackAlpha.500"
            sx={{
              _focus: {
                boxShadow: 'none'
              }
            }}
            _hover={{
              backgroundColor: 'white',
              color: 'black'
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImageDetailed;
