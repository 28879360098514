import { useEffect, useRef, useState } from 'react';
import {
  Circle,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';

const VideoDetailed = ({ src, isOpen, onClose }) => {
  const ref = useRef();

  const [videoClicked, setVideoClicked] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setVideoClicked(false);
    }
  }, [isOpen]);

  const handleOnClickPlay = () => {
    if (ref.current) {
      setVideoClicked(true);
      ref.current.play();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} allowPinchZoom isCentered>
        <ModalOverlay />
        <ModalContent maxW="fit-content" bg="transparent">
          <video
            ref={ref}
            src={src}
            controls={videoClicked}
            position="relative"
            style={{
              borderRadius: '10px',
              maxHeight: '80vh',
              maxWidth: '80vw',
              backgroundColor: 'black'
            }}
          >
            Your browser does not support the video tag.
          </video>
          {!videoClicked && (
            <Flex
              justify="center"
              align="center"
              position="absolute"
              bg="blackAlpha.500"
              height="full"
              width="full"
              cursor="pointer"
              onClick={handleOnClickPlay}
            >
              <Circle size={12} bg="white">
                <Text color="black" fontSize="xl">
                  <FontAwesomeIcon icon={faPlay} />
                </Text>
              </Circle>
            </Flex>
          )}
          <ModalCloseButton
            color="white"
            bg="blackAlpha.500"
            sx={{
              _focus: {
                boxShadow: 'none'
              }
            }}
            _hover={{
              backgroundColor: 'white',
              color: 'black'
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default VideoDetailed;
