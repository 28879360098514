import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Stack,
  Switch
} from '@chakra-ui/react';
import DescriptionFormControl from 'components/DescriptionFormControl';
import ImageSelect from 'components/ImageSelect';
import TitleInput from 'components/TitleInput';
import IconTooltip from 'components/tooltips/IconTooltip';

const SurveyForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const values = watch();

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues);
    }
  }, [defaultValues, isOpen, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="image">
          {!defaultValues && (
            <>
              <FormLabel display="inline-block">{`${t(
                'common.choose_media'
              )}`}</FormLabel>
              <FormHelperText display="inline">{`(${t(
                'common.optional'
              )})`}</FormHelperText>
            </>
          )}
          <ImageSelect
            object={values}
            setValue={setValue}
            uploadVideo
            preloadImage={!defaultValues}
          />
        </FormControl>
        <FormControl id="title">
          <FormLabel>{t('common.title')}</FormLabel>
          <TitleInput control={control} />
        </FormControl>
        <DescriptionFormControl
          control={control}
          setValue={text => setValue('description', text)}
        />
        <FormControl id="kiosk_mode">
          <HStack my={2} spacing={4} justifyContent="space-between">
            <FormLabel>
              {t('survey.kiosk_mode')}
              <IconTooltip
                fontSize="sm"
                mx={2}
                display="inline-block"
                color="gray"
                label={t('survey.kiosk_mode_explainer')}
              />
            </FormLabel>
            <Controller
              name="kiosk_mode"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <Switch
                  isChecked={value}
                  onChange={event => onChange(event.target.checked)}
                />
              )}
            />
          </HStack>
        </FormControl>
        <Input {...register('image')} hidden />
        <Input {...register('unsplash')} hidden />
        <Input {...register('video')} hidden />
        <Button
          alignSelf="flex-end"
          type="submit"
          colorScheme="teal"
          isDisabled={!isValid}
          isLoading={isSubmitting}
        >
          {t('button.save')}
        </Button>
      </Stack>
    </form>
  );
};

export default SurveyForm;
