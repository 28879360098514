import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  AspectRatio,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack
} from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';
import TitleDescription from 'components/TitleDescription';
import GuestHeader from 'features/navigation/GuestHeader';
import api from 'utils/api';
import LoadingWrapper from 'components/LoadingWrapper';
import SurveyResultTab from './SurveyResultTab';

const SharedSurveyResultsDetailed = () => {
  const { t } = useTranslation();
  const { slug, code } = useParams();
  const [filter, setFilter] = useState([]);

  const queryClient = useQueryClient();

  const hive = queryClient.getQueryData(['hive', slug]);

  const {
    data: survey,
    status,
    isSuccess
  } = useQuery(['survey', code], async () => {
    const { data } = await api.get(`/shared/survey-results/${code}`);
    return data;
  });

  const {
    status: fieldsStatus,
    data: fields,
    isSuccess: fieldsIsSuccess
  } = useQuery(['survey', code, 'fields', filter], async () => {
    const options = filter.map(field => field.options.map(o => o.id)).flat();
    const params = new URLSearchParams({
      results_code: code,
      options: options.join(',')
    });
    const { data } = await api.get(`/surveys/fields?${params.toString()}`);
    return data;
  });

  return (
    <Flex direction="column">
      <GuestHeader
        darkLogo={hive?.dark_logo}
        lightLogo={hive?.light_logo}
        showAvatar={false}
      />
      <Container maxW="container.lg" py={8}>
        <LoadingWrapper
          statuses={[status]}
          errorMessages={[
            t('common.could_not_fetch_data_please_try_again_later', {
              data: t('common.survey').toLowerCase()
            })
          ]}
        >
          {isSuccess && (
            <Stack spacing={8}>
              <Heading fontSize="3xl">{t('common.survey')}</Heading>
              <SimpleGrid columns={[1, null, 2]} spacing={8}>
                <AspectRatio ratio={16 / 9}>
                  <CoverMedia object={survey} clickToFullscreen />
                </AspectRatio>
                <TitleDescription
                  title={survey.title}
                  description={survey.description}
                  noOfLines={5}
                />
              </SimpleGrid>
              {fieldsIsSuccess && (
                <SurveyResultTab
                  fields={fields}
                  fieldsStatus={fieldsStatus}
                  fieldsIsSuccess={fieldsIsSuccess}
                  survey={survey}
                  filter={filter}
                  setFilter={setFilter}
                  resultsCode={code}
                  showShareResults={false}
                />
              )}
            </Stack>
          )}
        </LoadingWrapper>
      </Container>
    </Flex>
  );
};

export default SharedSurveyResultsDetailed;
