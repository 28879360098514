import { format } from 'date-fns';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  AvatarGroup,
  Badge,
  Card as ChakraCard,
  CardBody as ChakraCardBody,
  CardFooter as ChakraCardFooter,
  CardHeader as ChakraCardHeader,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useColorMode
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckToSlot,
  faCommentAlt,
  faThumbsDown,
  faThumbsUp
} from '@fortawesome/pro-regular-svg-icons';
import { faEyeSlash, faStar } from '@fortawesome/pro-solid-svg-icons';
import IconTooltip from 'components/tooltips/IconTooltip';
import CoverMedia from 'components/CoverMedia';
import TruncationTooltip from 'components/tooltips/TruncationTooltip';
import { findMedia } from 'utils/media';

const CardListItem = ({ card, onClick }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'CARD',
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    }),
    item: card
  }));

  const { colorMode } = useColorMode();

  const { t } = useTranslation();

  let shownContributors = [];
  if (card?.contributors) {
    shownContributors = [...card.contributors]?.splice(
      0,
      card.contributors.length >= 3 ? 3 : card.contributors.length
    );
  }

  return (
    <ChakraCard
      variant="outline"
      cursor="pointer"
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={onClick}
    >
      <ChakraCardHeader>
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          fontSize="sm"
        >
          <HStack alignItems="flex-start">
            {shownContributors.length > 0 && (
              <AvatarGroup size="sm" alignSelf="center">
                {shownContributors.map(member => (
                  <Avatar
                    key={member.id}
                    src={
                      member?.avatar
                        ? member.avatar
                        : member?.third_party_avatar
                          ? member.third_party_avatar
                          : null
                    }
                    name={member?.full_name}
                  />
                ))}
              </AvatarGroup>
            )}
            <Stack spacing={0} justifyContent="flex-start">
              {shownContributors.length > 0 && (
                <Text whiteSpace="nowrap" fontSize="sm" fontWeight="bold">
                  {shownContributors.length === 1
                    ? shownContributors[0].first_name
                    : `${shownContributors.length} ${t('common.contributors')}`}
                </Text>
              )}
              <Text fontSize="xs">
                {format(new Date(card.created_at), 'dd MMM yyyy')}
              </Text>
            </Stack>
          </HStack>

          {(card.hidden || card.scored) && (
            <HStack spacing={4} pt={1}>
              {card.scored && (
                <IconTooltip
                  color={colorMode === 'light' ? 'yellow.400' : 'yellow.200'}
                  icon={faStar}
                  label={t('card.scored_by_me')}
                  placement="top"
                />
              )}
              {card.hidden && (
                <IconTooltip
                  icon={faEyeSlash}
                  label={t('common.hidden_one')}
                  placement="top"
                />
              )}
            </HStack>
          )}
        </Flex>
      </ChakraCardHeader>
      {findMedia(card) && (
        <CoverMedia rounded={null} object={card} height="100px" />
      )}
      <ChakraCardBody>
        <Stack spacing={4} justifyContent="flex-start">
          <Heading fontSize="lg" noOfLines={2}>
            {card.title}
          </Heading>
          {card.stripped_description && (
            <Text noOfLines={3} fontSize="sm">
              {card.stripped_description}
            </Text>
          )}
        </Stack>
      </ChakraCardBody>
      <ChakraCardFooter>
        <Flex width="full" alignItems="center" justifyContent="space-between">
          <HStack fontSize="sm">
            <FontAwesomeIcon icon={faCommentAlt} />
            <Text width="24px">{card.metrics.comments}</Text>
            {card.reaction_type && (
              <HStack>
                <FontAwesomeIcon
                  icon={
                    card.reaction_type === 'VOTE' ? faCheckToSlot : faThumbsUp
                  }
                />
                <Text width="24px">{card.metrics.upvotes}</Text>
              </HStack>
            )}
            {card.use_downvotes && (
              <>
                <FontAwesomeIcon icon={faThumbsDown} />
                <Text width="24px">{card.metrics.downvotes}</Text>
              </>
            )}
          </HStack>
          {(card.is_inbox || card.step) && (
            <Badge
              whiteSpace="normal"
              colorScheme={card.step ? card.step.color : 'gray'}
            >
              <TruncationTooltip
                label={card.step ? card.step.name : t('common.inbox')}
              >
                {card.step ? card.step.name : t('common.inbox')}
              </TruncationTooltip>
            </Badge>
          )}
        </Flex>
      </ChakraCardFooter>
    </ChakraCard>
  );
};

export default CardListItem;
