import {
  Box,
  Circle,
  Flex,
  Image,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import AttachmentActionMenu from './AttachmentActionMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faFilePdf,
  faFileVideo,
  faFileZipper,
  faImage
} from '@fortawesome/pro-duotone-svg-icons';
import ImageDetailed from './ImageDetailed';
import VideoDetailed from './VideoDetailed';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';

const FileAttachment = ({ attachment }) => (
  <Stack
    width="full"
    flexDirection="column"
    alignItems="flex-start"
    padding={6}
    spacing={4}
  >
    <AttachmentIcon filename={attachment.file} />
    <Text wordBreak="break-all" overflowWrap="break-word" noOfLines={2}>
      {attachment.name}
    </Text>
  </Stack>
);

const AttachmentIcon = ({ filename }) => {
  const imageColor = useColorModeValue('blue.500', 'blue.200');
  const videoColor = useColorModeValue('purple.500', 'purple.200');
  const pdfColor = useColorModeValue('red.500', 'red.200');
  const zipColor = useColorModeValue('yellow.500', 'yellow.200');
  const fileColor = useColorModeValue('teal.500', 'teal.200');

  // Use image icon
  if (filename.match(/[.](gif|jpg|jpeg|png|tiff|webp)(?:$|\?)/i)) {
    return (
      <Text fontSize="2xl" color={imageColor}>
        <FontAwesomeIcon icon={faImage} />
      </Text>
    );
    // Use pdf icon
  } else if (filename.match(/[.]pdf(?:$|\?)/i)) {
    return (
      <Text fontSize="2xl" color={pdfColor}>
        <FontAwesomeIcon icon={faFilePdf} />
      </Text>
    );
  } else if (filename.match(/[.](rar|zip)(?:$|\?)/i)) {
    return (
      <Text fontSize="2xl" color={zipColor}>
        <FontAwesomeIcon icon={faFileZipper} />
      </Text>
    );
    // Check if video
  } else if (filename.match(/[.](mp4|mov|avi|mkv|webm)(?:$|\?)/i)) {
    return (
      <Text fontSize="2xl" color={videoColor}>
        <FontAwesomeIcon icon={faFileVideo} />
      </Text>
    );
  }
  // Use file icon
  return (
    <Text fontSize="2xl" color={fileColor}>
      <FontAwesomeIcon icon={faFileAlt} />
    </Text>
  );
};

const AttachmentListItem = ({
  attachment,
  onClickDelete,
  onClickUpdate,
  canEdit
}) => {
  const {
    isOpen: showImageModal,
    onOpen: openImageModal,
    onClose: closeImageModal
  } = useDisclosure();

  const {
    isOpen: showVideoModal,
    onOpen: openVideoModal,
    onClose: closeVideoModal
  } = useDisclosure();

  const downloadAttachment = () => {
    try {
      if (attachment.file?.toLowerCase().match(/\.pdf(?:$|\?)/)) {
        window.open(attachment.file, '_blank');
      } else {
        const link = document.createElement('a');
        link.href = attachment.download_url;
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      console.error('Failed to download file', error);
    }
  };

  const isImage =
    attachment.file?.match(/[.](gif|jpg|jpeg|png|tiff|webp)(?:$|\?)/i) ||
    attachment.type?.match(/(image\/|unsplash)/i);

  const isVideo = attachment.file?.match(/[.](mp4|mov|avi|mkv|webm)(?:$|\?)/i);

  return (
    <>
      <Flex
        position="relative"
        borderWidth={1}
        alignItems="space-between"
        rounded="lg"
        cursor="pointer"
        height="160px"
        width="full"
        onClick={() => {
          downloadAttachment();
        }}
      >
        <Box
          position="absolute"
          right={2}
          top={2}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {canEdit && (
            <AttachmentActionMenu
              isImageOrVideo={isImage || isVideo}
              onClickDelete={() => {
                onClickDelete(attachment);
              }}
              onClickUpdate={() => {
                onClickUpdate(attachment);
              }}
              onClickSetImage={null}
            />
          )}
        </Box>
        {isImage ? (
          <Image
            src={attachment.file}
            width="full"
            rounded="lg"
            objectFit="cover"
            onClick={e => {
              e.stopPropagation();
              openImageModal();
            }}
            fallback={<FileAttachment attachment={attachment} />}
          />
        ) : isVideo ? (
          <Flex
            position="relative"
            width="full"
            height="full"
            onClick={e => {
              e.stopPropagation();
              openVideoModal();
            }}
          >
            <video
              src={attachment.file}
              controls={null}
              position="relative"
              width="100%"
              style={{
                borderRadius: '0.5rem',
                objectFit: 'cover'
              }}
            >
              Your browser does not support the video tag.
            </video>
            <Flex
              position="absolute"
              justify="center"
              align="center"
              bg="blackAlpha.500"
              borderRadius="lg"
              height="full"
              width="full"
              cursor="pointer"
            >
              <Circle size={12} bg="white">
                <Text color="black" fontSize="xl">
                  <FontAwesomeIcon icon={faPlay} />
                </Text>
              </Circle>
            </Flex>
          </Flex>
        ) : (
          <FileAttachment attachment={attachment} />
        )}
      </Flex>
      <ImageDetailed
        src={attachment.file}
        isOpen={showImageModal}
        onClose={closeImageModal}
      />
      <VideoDetailed
        src={attachment.file}
        isOpen={showVideoModal}
        onClose={closeVideoModal}
      />
    </>
  );
};

export default AttachmentListItem;
