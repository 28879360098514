import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import MemberAvatar from 'components/MemberAvatar';
import { useAuth } from 'providers/AuthProvider';
import {
  Box,
  Container,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons';
import { useUi } from 'providers/UiProvider';

const GuestHeader = ({ darkLogo, lightLogo, showAvatar = true }) => {
  const { inTeams } = useUi();
  const { logout } = useAuth();
  const { data: me } = useQuery('me', { enabled: showAvatar });
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleOnClickSignOut = () => {
    logout();
    navigate('/');
  };

  const logo = useColorModeValue(
    darkLogo ? darkLogo : '/hives_dark.png',
    lightLogo ? lightLogo : '/hives_light.png'
  );

  return (
    <Flex borderBottomWidth={1} height="70px" alignItems="center">
      <Container maxW="container.lg">
        <Flex alignItems="center" justifyContent="space-between">
          <Image src={logo} height={8} />
          {showAvatar &&
            (inTeams ? (
              <Menu placement="bottom-end">
                <MenuButton>
                  <MemberAvatar
                    member={me}
                    size="sm"
                    width="40px"
                    height="40px"
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={handleOnClickSignOut}>
                    <Box mr={2}>
                      <FontAwesomeIcon icon={faSignOut} className="fa-w-20" />
                    </Box>
                    <Text>{t('common.sign_out')}</Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <MemberAvatar member={me} size="sm" width="40px" height="40px" />
            ))}
        </Flex>
      </Container>
    </Flex>
  );
};

export default GuestHeader;
