import { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Select } from 'chakra-react-select';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Stack,
  Text
} from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';

const ChartForm = ({ defaultValues, onSubmit, workflowId }) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting }
  } = useForm({ mode: 'onChange' });

  const { t } = useTranslation();

  const {
    status: stepsStatus,
    data: steps,
    isSuccess: stepsIsSuccess
  } = useQuery('steps', async () => {
    const { data } = await api.get(`/steps?workflow=${workflowId}`);
    return data;
  });

  const stepOptions = useMemo(() => {
    if (steps) {
      return [
        {
          label: t('common.form'),
          id: null,
          form: true
        },
        {
          label: t('common.inbox'),
          id: null,
          form: false
        },
        ...steps.map(step => ({
          label: step.name,
          id: step.id
        }))
      ];
    }
    return [];
  }, [t, steps]);

  const parameters = watch('parameters');

  const averageOptions = [
    {
      value: true,
      label: t('common.average')
    },
    {
      value: false,
      label: t('common.total')
    }
  ];

  return (
    <LoadingWrapper
      statuses={[stepsStatus]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.types').toLowerCase()
        }),
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.steps').toLowerCase()
        })
      ]}
    >
      {stepsIsSuccess ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={8}>
            <FormControl>
              <FormLabel>{t('common.field')}</FormLabel>
              <Input
                maxLength={80}
                placeholder={t('placeholder.enter_field')}
                {...register('field', { required: true })}
                autoFocus
              />
            </FormControl>
            <FormControl>
              <HStack mb={2}>
                <Text>{t('chart.parameters')}</Text>
                <Spacer />
                <Text>{parameters}</Text>
              </HStack>
              <Controller
                name="parameters"
                defaultValue={3}
                control={control}
                render={({ field }) => (
                  <Slider {...field} min={1} max={8}>
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                )}
              />
            </FormControl>
            <FormControl id="step">
              <FormLabel>{t('common.step')}</FormLabel>
              <Controller
                name="step"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={stepOptions}
                    placeholder={`${t('common.select')} ${t(
                      'common.step'
                    ).toLowerCase()}`}
                    getOptionLabel={step => step.label}
                    getOptionValue={step => step}
                    onChange={value => {
                      field.onChange(value);
                    }}
                    useBasicStyles
                    defaultValue={
                      defaultValues?.step
                        ? stepOptions.find(
                            step => step.id === defaultValues.step.id
                          )
                        : defaultValues?.form
                          ? stepOptions[0]
                          : stepOptions[1]
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl id="scorer">
              <FormLabel>{t('chart.who_can_see_results_and_score')}</FormLabel>
              <Controller
                name="scorer"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder={t('common.select')}
                    options={[
                      {
                        id: 'COL',
                        label: t('common.collection_managers')
                      },
                      {
                        id: 'CON',
                        label: t('common.contributors')
                      },
                      {
                        id: 'EVE',
                        label: t('common.everyone')
                      }
                    ]}
                    getOptionLabel={scorer => scorer.label}
                    getOptionValue={scorer => scorer.id}
                    onChange={value => {
                      field.onChange(value);
                    }}
                    useBasicStyles
                  />
                )}
              />
            </FormControl>
            <FormControl id="average">
              <FormLabel>{t('chart.show_result_as')}</FormLabel>
              <Controller
                name="average"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder={t('common.select')}
                    options={averageOptions}
                    onChange={value => {
                      field.onChange(value);
                    }}
                    useBasicStyles
                    defaultValue={averageOptions[0]}
                  />
                )}
              />
            </FormControl>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                colorScheme="teal"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                {t('button.save')}
              </Button>
            </Flex>
          </Stack>
        </form>
      ) : null}
    </LoadingWrapper>
  );
};

export default ChartForm;
