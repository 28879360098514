import { Reorder, useDragControls } from 'framer-motion';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDots } from '@fortawesome/pro-solid-svg-icons';

const BoardReorderItem = ({ board }) => {
  const controls = useDragControls();
  return (
    <Flex borderWidth={0} borderBottom="none" py={4}>
      <Reorder.Item
        as="div"
        value={board}
        dragListener={false}
        dragControls={controls}
      >
        <HStack spacing={4} userSelect="none">
          <Text
            cursor="grab"
            variant="light"
            onPointerDown={e => {
              controls.start(e);
            }}
          >
            <FontAwesomeIcon icon={faGripDots} />
          </Text>
          <Text>{board.title}</Text>
        </HStack>
      </Reorder.Item>
    </Flex>
  );
};

export default BoardReorderItem;
