import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  HStack,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckToSlot,
  faThumbsDown,
  faThumbsUp
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCheckToSlot as faCheckToSlotSolid,
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid
} from '@fortawesome/pro-solid-svg-icons';

const Vote = ({
  voted,
  downVotes,
  upVotes,
  onClickVote,
  useDownvotes = false,
  reactionType
}) => {
  const { t } = useTranslation();

  const downVoteColor = useColorModeValue('red.500', 'red.200');
  const likedColor = useColorModeValue('blue.500', 'blue.200');
  const votedColor = useColorModeValue('teal.500', 'teal.200');

  return (
    <Stack
      spacing={4}
      justifyContent={['flex-end', null, 'flex-start']}
      alignItems="flex-end"
    >
      <ButtonGroup variant="outline">
        {reactionType && (
          <Button onClick={() => onClickVote('UP')}>
            <HStack spacing={2}>
              <Text
                color={
                  voted === 'UP' && reactionType === 'VOTE'
                    ? votedColor
                    : likedColor
                }
              >
                <FontAwesomeIcon
                  icon={
                    voted === 'UP'
                      ? reactionType === 'VOTE'
                        ? faCheckToSlotSolid
                        : faThumbsUpSolid
                      : reactionType === 'VOTE'
                        ? faCheckToSlot
                        : faThumbsUp
                  }
                />
              </Text>
              <Text>
                {reactionType === 'VOTE'
                  ? t('common.vote_verb')
                  : t('common.like_verb')}
              </Text>
              <Text>{upVotes}</Text>
            </HStack>
          </Button>
        )}
        {useDownvotes && (
          <Button onClick={() => onClickVote('DOWN')}>
            <HStack spacing={downVotes >= 10 ? 2 : 1}>
              <Text color={voted === 'DOWN' && downVoteColor}>
                <FontAwesomeIcon
                  icon={voted === 'DOWN' ? faThumbsDownSolid : faThumbsDown}
                />
              </Text>
              <Text minW="16px" textAlign="right">
                {downVotes}
              </Text>
            </HStack>
          </Button>
        )}
      </ButtonGroup>
    </Stack>
  );
};

export default Vote;
