import { Avatar, AvatarGroup, Button, HStack, Text } from '@chakra-ui/react';

const AvatarGroupButton = ({
  showCount,
  members,
  onClick,
  max = 3,
  ...rest
}) => {
  let shownMembers = [];
  if (members) {
    shownMembers = [...members]?.splice(
      0,
      members?.length >= max ? max : members?.length
    );
  }

  return (
    <Button px={4} onClick={onClick} variant="outline" {...rest}>
      <HStack px={0}>
        <AvatarGroup size="sm">
          {shownMembers.length > 0 ? (
            shownMembers?.map((member, index) => (
              <Avatar
                key={`${member?.id}_${index}`}
                src={
                  member?.avatar
                    ? member.avatar
                    : member?.third_party_avatar
                      ? member.third_party_avatar
                      : null
                }
                name={member?.full_name}
              />
            ))
          ) : (
            <Avatar />
          )}
        </AvatarGroup>
        {showCount && <Text>{members?.length}</Text>}
      </HStack>
    </Button>
  );
};

export default AvatarGroupButton;
