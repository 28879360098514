export const findMedia = item => {
  if (item?.placeholder_image) {
    return item.placeholder_image.image;
  }
  if (item?.file) {
    return item.file;
  }
  if (item?.video) {
    return item.video;
  }
  if (item?.image || isImage(item)) {
    return item.image;
  }
  if (item?.unsplash) {
    return item.unsplash;
  }
  return null;
};

export const isImage = url => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/i.test(url);
};

export const isVideo = url => {
  return /\.(mp4|ogg|webm)$/i.test(url);
};
