import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDisclosure, useToast } from '@chakra-ui/react';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import AttachmentForm from './AttachmentForm';
import AttachmentListItem from './AttachmentListItem';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';

const AttachmentList = ({
  code,
  modelConfig,
  canEdit = false,
  onAttachmentDeleted = _ => {}
}) => {
  const {
    isOpen: showDeleteAttachmentModal,
    onOpen: openDeleteAttachmentModal,
    onClose: closeDeleteAttachmentModal
  } = useDisclosure();

  const {
    isOpen: showUpdateAttachmentModal,
    onOpen: openUpdateAttachmentModal,
    onClose: closeUpdateAttachmentModal
  } = useDisclosure();

  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const { t } = useTranslation();

  const toast = useToast();

  const queryClient = useQueryClient();

  const { data: attachments, isSuccess } = useQuery(
    ['attachments', modelConfig?.singular, modelConfig?.modelId],
    async () => {
      const { data } = await api.get(
        code
          ? `/${modelConfig?.plural}/attachments?${modelConfig?.singular}=${modelConfig.modelId}&code=${code}`
          : `/${modelConfig?.plural}/attachments?${modelConfig?.singular}=${modelConfig.modelId}`
      );
      return data;
    }
  );

  const deleteAttachmentMutation = useMutation(
    () =>
      api.delete(
        code
          ? `/${modelConfig?.plural}/attachments/${selectedAttachment.id}?code=${code}`
          : `/${modelConfig?.plural}/attachments/${selectedAttachment.id}`
      ),
    {
      onSuccess: () => {
        queryClient.setQueryData(
          ['attachments', modelConfig?.singular, modelConfig?.modelId],
          attachments.filter(a => a.id !== selectedAttachment.id)
        );
        toast({
          title: t('toast.delete_success', {
            entity: t('common.attachment')
          }),
          status: 'success'
        });
        closeDeleteAttachmentModal();
        onAttachmentDeleted(selectedAttachment);
      }
    }
  );

  const updateAttachmentMutation = useMutation(
    attachment =>
      api.patch(
        code
          ? `/${modelConfig?.plural}/attachments/${selectedAttachment.id}?code=${code}`
          : `/${modelConfig?.plural}/attachments/${selectedAttachment.id}`,
        attachment
      ),
    {
      onSuccess: ({ data: attachment }) => {
        queryClient.setQueryData(
          ['attachments', modelConfig?.singular, modelConfig?.modelId],
          attachments.map(a =>
            a.id === selectedAttachment.id ? attachment : a
          )
        );
        toast({
          title: t('toast.update_success', {
            entity: t('common.attachment')
          }),
          status: 'success'
        });
        closeUpdateAttachmentModal();
      }
    }
  );

  const handleOnDeleteAttachment = async () => {
    await deleteAttachmentMutation.mutateAsync();
  };

  const handleOnUpdateAttachment = async attachment => {
    await updateAttachmentMutation.mutateAsync(attachment);
  };

  return isSuccess ? (
    <>
      {attachments.map(attachment => (
        <AttachmentListItem
          key={attachment.id}
          attachment={attachment}
          onClickDelete={attachment => {
            setSelectedAttachment(attachment);
            openDeleteAttachmentModal();
          }}
          onClickUpdate={attachment => {
            setSelectedAttachment(attachment);
            openUpdateAttachmentModal();
          }}
          canEdit={canEdit}
        />
      ))}
      <ModalWrapper
        title={t('common.attachment')}
        isOpen={showUpdateAttachmentModal}
        onClose={closeUpdateAttachmentModal}
        returnFocusOnClose={true}
      >
        <AttachmentForm
          defaultValues={selectedAttachment}
          isOpen={showUpdateAttachmentModal}
          onSubmit={handleOnUpdateAttachment}
        />
      </ModalWrapper>
      <ConfirmationModal
        deleteText={t(
          'confirmation.this_data_will_be_permanently_removed_and_cannot_be_restored',
          { data: t('common.attachment').toLowerCase() }
        )}
        isOpen={showDeleteAttachmentModal}
        onClose={closeDeleteAttachmentModal}
        onDelete={handleOnDeleteAttachment}
      />
    </>
  ) : null;
};

export default AttachmentList;
