import { Reorder } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Accordion,
  Button,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';
import InboxStepListItem from './InboxStepListItem';
import StepForm from './StepForm';
import StepListItem from './StepListItem';
import FormStepListItem from './FormStepListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsFromLine,
  faArrowsToLine,
  faPlus
} from '@fortawesome/pro-regular-svg-icons';
import IconTooltip from 'components/tooltips/IconTooltip';

let timer = null;

const StepList = ({ workflow, onUpdateWorkflow }) => {
  const [accordionIndex, setAccordionIndex] = useState([]);
  const toast = useToast();

  const {
    isOpen: showCreateStepModal,
    onOpen: openCreateStepModal,
    onClose: closeCreateStepModal
  } = useDisclosure();

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const {
    status,
    data: steps,
    isSuccess
  } = useQuery(['steps', workflow.id], async () => {
    const { data } = await api.get(`/steps?workflow=${workflow.id}`);
    return data;
  });

  const createStepMutation = useMutation(step => api.post('/steps', step), {
    onSuccess: ({ data: step }) => {
      queryClient.setQueryData(['steps', workflow.id], [...steps, step]);
      closeCreateStepModal();
      toast({
        title: t('toast.create_success', {
          entity: t('common.step')
        }),
        status: 'success'
      });
    },
    onError: () => {
      toast({
        title: t('toast.create_error', {
          entity: t('common.step')
        }),
        status: 'error'
      });
    }
  });

  const handleOnCreateStep = async step => {
    await createStepMutation.mutateAsync({ ...step, workflow: workflow.id });
  };

  const handleOnToggleAccordion = newIndex => {
    if (accordionIndex.includes(newIndex)) {
      setAccordionIndex([
        ...accordionIndex.filter(index => index !== newIndex)
      ]);
    } else {
      setAccordionIndex([...accordionIndex, newIndex]);
    }
  };

  const onFieldChangeStep = field => {
    setAccordionIndex([]);
    handleOnToggleAccordion(
      field.step
        ? steps.findIndex(step => step.id === field.step.id) + 2
        : field.form
          ? 0
          : 1
    );
  };

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.steps').toLowerCase()
        })
      ]}
    >
      {isSuccess && (
        <Stack spacing={8}>
          <Flex alignItems="center" justifyContent="space-between">
            <HStack spacing={12} alignItems="center">
              <HStack spacing={2} alignItems="center">
                <Text fontSize="xl" fontWeight="bold">
                  {t('common.steps')}
                </Text>
                <IconTooltip
                  label={`${t('collection.step_tooltip')}. ${t(
                    'template.steps_instructions'
                  )}`}
                  alignSelf="center"
                  color="gray"
                />
              </HStack>
              <HStack spacing={4} alignItems="center">
                <Button
                  leftIcon={<FontAwesomeIcon icon={faArrowsFromLine} />}
                  variant="outline"
                  size="sm"
                  onClick={() =>
                    setAccordionIndex([
                      0,
                      1,
                      ...steps.map((_, index) => index + 2)
                    ])
                  }
                >
                  {t('button.expand_all')}
                </Button>
                <Button
                  leftIcon={<FontAwesomeIcon icon={faArrowsToLine} />}
                  variant="outline"
                  size="sm"
                  onClick={() => setAccordionIndex([])}
                >
                  {t('button.collapse_all')}
                </Button>
              </HStack>
            </HStack>
            <Button colorScheme="teal" onClick={openCreateStepModal}>
              <HStack>
                <FontAwesomeIcon icon={faPlus} />
                <Text>{t('common.step')}</Text>
              </HStack>
            </Button>
          </Flex>
          <Accordion
            allowMultiple
            index={accordionIndex}
            onChange={newIndex => setAccordionIndex([...newIndex])} //handleOnToggleAccordion(newIndex[0])}
          >
            <FormStepListItem
              workflow={workflow}
              onUpdateWorkflow={onUpdateWorkflow}
              onFieldChangeStep={onFieldChangeStep}
              isOpen={accordionIndex.includes(0)}
            />
            <InboxStepListItem
              workflow={workflow}
              onSubmit={onUpdateWorkflow}
              onFieldChangeStep={onFieldChangeStep}
              onUpdateWorkflow={onUpdateWorkflow}
              isOpen={accordionIndex.includes(1)}
            />
            <Reorder.Group
              axis="y"
              values={steps}
              onReorder={steps => {
                if (timer != null) {
                  clearTimeout(timer);
                }
                timer = setTimeout(() => {
                  try {
                    const steps = queryClient.getQueryData([
                      'steps',
                      workflow.id
                    ]);
                    api.patch(`/workflows/${workflow.id}/reorder-steps`, {
                      steps: steps.map(step => step.id)
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }, 1000);
                queryClient.setQueryData(['steps', workflow.id], steps);
              }}
            >
              {steps.map((step, index) => (
                <StepListItem
                  key={step.id}
                  step={step}
                  workflow={workflow}
                  onDrag={() => {
                    setAccordionIndex([null]);
                  }}
                  onFieldChangeStep={onFieldChangeStep}
                  isOpen={accordionIndex.includes(index + 2)}
                />
              ))}
            </Reorder.Group>
          </Accordion>
          <ModalWrapper
            title={t('template.create_step')}
            size="full"
            isOpen={showCreateStepModal}
            onClose={closeCreateStepModal}
          >
            <StepForm
              defaultValues={{ color: 'gray' }}
              isOpen={showCreateStepModal}
              onSubmit={handleOnCreateStep}
            />
          </ModalWrapper>
        </Stack>
      )}
    </LoadingWrapper>
  );
};

export default StepList;
