import {
  Button,
  Container,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import { format } from 'date-fns';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import api from 'utils/api';

const SurveyTextAnswerSummaries = ({ field, resultsCode }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const modalRef = useRef();

  const {
    isOpen: showAllAnswersModal,
    onOpen: openAllAnswersModal,
    onClose: closeAllAnswersModal
  } = useDisclosure();

  const {
    data: surveyTextAnswerSummaries,
    isFetching,
    status,
    isSuccess,
    refetch
  } = useQuery(
    ['survey', 'answers', 'summaries', field.id, { limit: 1 }],
    async () => {
      let params = new URLSearchParams();
      params.append('field', field.id);
      params.append('limit', 1);
      params.append('ordering', '-created_at');
      if (resultsCode) {
        params.append('results_code', resultsCode);
      }
      const { data } = await api.get(
        `/surveys/answer-summaries?${params.toString()}`
      );
      return data;
    }
  );

  const createSurveyFieldSummaryMutation = useMutation(
    payload => api.post('/surveys/answer-summaries', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'survey',
          'answers',
          'summaries',
          field.id,
          { limit: 1 }
        ]);
        refetch();
      }
    }
  );

  const handleOnSummaryRecieved = async ({ content }) => {
    await createSurveyFieldSummaryMutation.mutateAsync({
      field: field.id,
      summary: content
    });
    setIsGenerating(false);
  };

  const handleOnGenerateSummary = async () => {
    try {
      setIsGenerating(true);
      const response = await api.post(
        '/services/generate-survey-field-summary',
        {
          field: field.id
        }
      );
      if (response.data) {
        handleOnSummaryRecieved({ ...response.data });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Stack spacing={8}>
      <Stack spacing={2} alignItems="flex-start">
        <HStack width="full" spacing={4} justifyContent="space-between">
          <Heading fontSize={['md', 'lg', null]}>
            {t('survey.ai_summaries')}
          </Heading>
          {!resultsCode && (
            <Button
              leftIcon={<FontAwesomeIcon icon={faSparkles} />}
              size="sm"
              alignSelf="flex-end"
              width="fit-content"
              colorScheme="teal"
              onClick={() => {
                queryClient.invalidateQueries([
                  'survey',
                  'summaries',
                  field.id
                ]);
                handleOnGenerateSummary();
              }}
              isLoading={isGenerating || isFetching}
            >
              {t('common.generate')}
            </Button>
          )}
        </HStack>
      </Stack>
      <LoadingWrapper statuses={[status]}>
        {isSuccess && surveyTextAnswerSummaries.results?.length > 0 ? (
          <Stack spacing={4}>
            {surveyTextAnswerSummaries?.results?.map(summary => (
              <Stack
                borderWidth={1}
                borderRadius="lg"
                padding={4}
                key={summary.id}
                spacing={4}
              >
                <Text>{summary.summary}</Text>
                <Text width="full" textAlign="right" fontSize="xs">
                  {`${format(new Date(summary.created_at), 'dd MMM yyyy')}, ${t(
                    'survey.at_answer_count',
                    { count: summary?.metrics?.answer_count || -1 }
                  )}`}
                </Text>
              </Stack>
            ))}
            <Button variant="link" onClick={openAllAnswersModal}>
              {t('button.see_all')}
            </Button>
          </Stack>
        ) : (
          <Text textAlign="center" variant="muted">
            {t('common.nothing_here_yet')}
          </Text>
        )}
      </LoadingWrapper>
      <Modal
        size="full"
        isOpen={showAllAnswersModal}
        onClose={closeAllAnswersModal}
      >
        <ModalOverlay />
        <ModalContent ref={modalRef} overflowY="scroll" maxHeight="90vh">
          <Container maxWidth="container.sm">
            <ModalHeader>{t('survey.ai_summaries')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {showAllAnswersModal && (
                <AllTextAnswerSummaries field={field} modalRef={modalRef} />
              )}
            </ModalBody>
            <ModalFooter />
          </Container>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const AllTextAnswerSummaries = ({ field, modalRef }) => {
  const { t } = useTranslation();

  const {
    status,
    data: surveyTextAnswerSummaries,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isSuccess
  } = useInfiniteQuery(
    ['survey', 'answers', 'summaries', field.id, { limit: 2 }],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/surveys/answer-summaries?field=${
          field.id
        }&offset=${pageParam}&limit=${2}&ordering=-created_at`
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.answers').toLowerCase()
        })
      ]}
    >
      {isSuccess && (
        <Stack>
          <InfiniteScroll
            hasMore={!isFetching && hasNextPage}
            loadMore={fetchNextPage}
            getScrollParent={() => modalRef.current}
          >
            <Stack spacing={4} maxHeight="70vh" pb={8}>
              <Stack spacing={4} pb={8}>
                {surveyTextAnswerSummaries?.pages?.map(page =>
                  page.results.map(summary => (
                    <Stack
                      borderWidth={1}
                      borderRadius="lg"
                      padding={4}
                      key={summary.id}
                      spacing={4}
                    >
                      <Text>{summary.summary}</Text>
                      <Text width="full" textAlign="right" fontSize="xs">
                        {`${format(
                          new Date(summary.created_at),
                          'dd MMM yyyy'
                        )}, ${t('survey.at_answer_count', {
                          count: summary?.metrics?.answer_count || -1
                        })}`}
                      </Text>
                    </Stack>
                  ))
                )}
                {isFetching && <LoadingIndicator />}
              </Stack>
            </Stack>
          </InfiniteScroll>
        </Stack>
      )}
    </LoadingWrapper>
  );
};

export default SurveyTextAnswerSummaries;
