import { useTranslation } from 'react-i18next';
import {
  Button,
  HStack,
  Spacer,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import SurveyAnswer from './SurveyAnswer';
import SurveySelection from './SurveySelection';
import SurveyFilterDrawer from './SurveyFilterDrawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faSliders } from '@fortawesome/pro-regular-svg-icons';
import ModalWrapper from 'components/ModalWrapper';
import ShareSurveyResults from './ShareSurveyResults';

const SurveyResultTab = ({
  survey,
  fields,
  fieldsStatus,
  fieldsIsSuccess,
  filter,
  setFilter,
  resultsCode,
  showShareResults = true
}) => {
  const { t } = useTranslation();

  const {
    isOpen: showShareResultsModal,
    onOpen: openShareResultsModal,
    onClose: closeShareResultsModal
  } = useDisclosure();

  const {
    isOpen: showFilterDrawer,
    onOpen: openFilterDrawer,
    onClose: closeFilterDrawer
  } = useDisclosure();

  const selectionFields = survey.fields.filter(
    field =>
      field.type === 'MULTI_SELECT' ||
      (field.type === 'SINGLE_SELECT' && field.options.length > 0)
  );

  const noOfActiveFilters = filter.length;

  return (
    <>
      <LoadingWrapper statuses={[fieldsStatus]}>
        {fieldsIsSuccess && (
          <>
            <Stack spacing={8}>
              <HStack spacing={4}>
                {showShareResults && (
                  <Button variant="outline" onClick={openShareResultsModal}>
                    <HStack>
                      <FontAwesomeIcon icon={faChartBar} />
                      <Text>{t('common.share_results')}</Text>
                    </HStack>
                  </Button>
                )}
                <Spacer />
                {noOfActiveFilters > 0 && (
                  <Text>
                    {noOfActiveFilters === 1
                      ? t('common.nr_filters_active_with_count_one', {
                          nr: noOfActiveFilters
                        })
                      : t('common.nr_filters_active_with_count_several', {
                          nr: noOfActiveFilters
                        })}
                  </Text>
                )}
                <Button
                  variant="outline"
                  onClick={
                    showFilterDrawer ? closeFilterDrawer : openFilterDrawer
                  }
                >
                  <FontAwesomeIcon icon={faSliders} />
                </Button>
              </HStack>

              <HStack justifyContent="flex-end"></HStack>

              {fields.map(field =>
                field.type === 'MULTI_SELECT' ||
                field.type === 'SINGLE_SELECT' ? (
                  <SurveySelection key={field.id} field={field} />
                ) : (
                  <SurveyAnswer
                    key={field.id}
                    field={field}
                    options={filter.map(field => field.options).flat()}
                    resultsCode={resultsCode}
                  />
                )
              )}
            </Stack>
          </>
        )}
      </LoadingWrapper>
      <ModalWrapper
        title={t('common.share_results')}
        isOpen={showShareResultsModal}
        onClose={closeShareResultsModal}
        size="xl"
      >
        <ShareSurveyResults survey={survey} />
      </ModalWrapper>
      <SurveyFilterDrawer
        filter={filter}
        setFilter={setFilter}
        selectionFields={selectionFields}
        isOpen={showFilterDrawer}
        onClose={closeFilterDrawer}
      />
    </>
  );
};

export default SurveyResultTab;
