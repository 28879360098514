import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Divider,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import AccessForm from 'components/AccessForm';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  ActionMenu,
  EditMenuItem,
  DeleteMenuItem
} from 'components/ActionMenu';
import LoadingWrapper from 'components/LoadingWrapper';
import MembersButton from 'components/MembersButton';
import ModalWrapper from 'components/ModalWrapper';
import ProjectForm from 'features/project/ProjectForm';
import ProjectList from 'features/project/ProjectList';
import TitleDescription from 'components/TitleDescription';
import api from 'utils/api';

const ChannelDetailed = () => {
  const {
    isOpen: showDeleteChannelModal,
    onOpen: openDeleteChannelModal,
    onClose: closeDeleteChannelModal
  } = useDisclosure();

  const {
    isOpen: showUpdateChannelModal,
    onOpen: openUpdateChannelModal,
    onClose: closeUpdateChannelModal
  } = useDisclosure();

  const {
    isOpen: showCreateProjectModal,
    onOpen: openCreateProjectModal,
    onClose: closeCreateProjectModal
  } = useDisclosure();

  const { channelId } = useParams();

  const navigate = useNavigate();
  const toast = useToast();

  const { slug } = useParams();
  const { data: profile } = useQuery(['profile', slug]);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    status,
    data: channel,
    isSuccess
  } = useQuery(['channel', channelId], async () => {
    const { data } = await api.get(`/channels/${channelId}`);
    return data;
  });

  const updateChannelMutation = useMutation(
    channel => api.patch(`/channels/${channelId}`, channel),
    {
      onSuccess: ({ data: channel }) => {
        closeUpdateChannelModal();
        queryClient.setQueryData(['channel', channelId], channel);
        const channels = queryClient.getQueryData(['channels', slug]);
        if (channels?.length > 0) {
          queryClient.setQueryData(
            ['channels', slug],
            channels.map(c => (c.id === channel.id ? channel : c))
          );
        } else {
          queryClient.setQueryData(['channels', slug], channel);
        }
        toast({
          title: t('toast.update_success', {
            entity: t('common.channel')
          }),
          status: 'success'
        });
      }
    }
  );

  const createProjectMutation = useMutation(
    project => api.post('/projects', project),
    {
      onSuccess: ({ data: project }) => {
        closeCreateProjectModal();
        toast({
          title: t('toast.create_success', {
            entity: t('common.project')
          }),
          status: 'success'
        });
        navigate(`/${slug}/project/${project.id}`);
      }
    }
  );

  const deleteChannelMutation = useMutation(
    () => api.delete(`/channels/${channelId}`),
    {
      onSuccess: () => {
        closeDeleteChannelModal();
        toast({
          title: t('toast.delete_success', {
            entity: t('common.channel')
          }),
          status: 'success'
        });
        const channels = queryClient.getQueryData(['channels', slug]);
        queryClient.setQueryData(
          ['channels', slug],
          channels.filter(c => c.id !== parseInt(channelId))
        );
        navigate(`/${slug}/channels`);
      }
    }
  );

  const handleOnUpdateChannel = async channel => {
    const domains = channel.domains
      ? channel.domains.map(domain => domain.id)
      : [];
    const groups = channel.groups ? channel.groups.map(group => group.id) : [];
    const members = channel.members
      ? channel.members.map(member => member.id)
      : [];
    const accessId = channel.access.id;
    await updateChannelMutation.mutateAsync({
      ...channel,
      domains,
      groups,
      members,
      access: accessId
    });
  };

  const handleOnCreateProject = async project => {
    await createProjectMutation.mutateAsync({
      ...project,
      active: true,
      channel: project.channel.id,
      template: project.template ? project.template.id : null
    });
  };

  const handleOnClickDelete = async () => {
    await deleteChannelMutation.mutateAsync();
  };

  const handleOnSubmitMembers = async ({ members }) => {
    await updateChannelMutation.mutateAsync({
      members: members.map(member => member.id)
    });
  };

  return (
    <Container maxW="container.lg" marginY={8}>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.channel').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack spacing={8}>
            <Stack spacing={4}>
              <TitleDescription
                title={channel.title}
                description={channel.description}
                button={
                  profile?.is_admin_or_manager ? (
                    <ActionMenu>
                      <EditMenuItem onClick={openUpdateChannelModal} />
                      <DeleteMenuItem onClick={openDeleteChannelModal} />
                    </ActionMenu>
                  ) : null
                }
              />
              <HStack>
                <Text fontWeight="bold">
                  {t('channel.access_to_this_channel')}:
                </Text>
                {channel.access === 'MEMBERS' ? (
                  <>
                    <MembersButton
                      max={3}
                      members={channel.members}
                      onSubmit={handleOnSubmitMembers}
                      modalTitle={t('common.contributors')}
                      canEdit={profile?.is_admin_or_manager}
                    />
                  </>
                ) : (
                  <Text fontWeight="bold">{t('common.everyone')}</Text>
                )}
              </HStack>
            </Stack>
            <Divider my={8} />
            <Stack spacing={8}>
              <ProjectList
                channelId={channelId}
                active
                showCreate={profile?.is_admin_or_manager}
                onClickCreate={openCreateProjectModal}
                headerText={t('common.ongoing_projects')}
              />
              <ProjectList
                channelId={channelId}
                headerText={t('common.archived_projects')}
              />
            </Stack>
            <ModalWrapper
              title={t('common.channel')}
              isOpen={showUpdateChannelModal}
              onClose={closeUpdateChannelModal}
            >
              <AccessForm
                defaultValues={{
                  title: channel.title,
                  description: channel.description,
                  access: channel.access,
                  domains: channel.domains,
                  groups: channel.groups,
                  members: channel.members
                }}
                isOpen={showUpdateChannelModal}
                onSubmit={handleOnUpdateChannel}
              />
            </ModalWrapper>
            <ModalWrapper
              title={t('project.create_project')}
              size="full"
              isOpen={showCreateProjectModal}
              onClose={closeCreateProjectModal}
            >
              <ProjectForm
                isOpen={showCreateProjectModal}
                defaultValues={{ channel }}
                onSubmit={handleOnCreateProject}
              />
            </ModalWrapper>
            <ConfirmationModal
              deleteText={t(
                'confirmation.all_information_in_this_channel_will_be_permanently_removed_and_cannot_be_restored'
              )}
              isOpen={showDeleteChannelModal}
              onClose={closeDeleteChannelModal}
              onDelete={handleOnClickDelete}
            />
          </Stack>
        ) : null}
      </LoadingWrapper>
    </Container>
  );
};

export default ChannelDetailed;
