import { Select } from 'chakra-react-select';
import { useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import CharacterCounterInput from 'components/CharacterCounterInput';

const FieldForm = ({
  workflow,
  defaultValues,
  onSubmit,
  addDefaultOptions = false
}) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange'
  });

  const {
    fields: options,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'options'
  });

  useEffect(() => {
    if (addDefaultOptions) {
      append([{ option: '' }, { option: '' }], { shouldFocus: false });
    }
  }, [append, addDefaultOptions]);

  const {
    status: stepsStatus,
    data: steps,
    isSuccess: stepsIsSuccess
  } = useQuery('steps', async () => {
    const { data } = await api.get(`/steps?workflow=${workflow.id}`);
    return data;
  });

  const stepOptions = useMemo(() => {
    if (steps) {
      return [
        {
          label: t('common.form'),
          id: null,
          form: true
        },
        {
          label: t('common.inbox'),
          id: null,
          form: false
        },
        ...steps.map(step => ({
          label: step.name,
          id: step.id,
          form: false
        }))
      ];
    }
    return [];
  }, [t, steps]);

  const stepWatch = watch(
    'step',
    defaultValues?.step
      ? stepOptions.find(step => step.id === defaultValues.step.id)
      : defaultValues?.form
        ? stepOptions[0]
        : stepOptions[1]
  );

  const helpTextMaxLength = 200;

  const averageOptions = [
    {
      value: true,
      label: t('common.average')
    },
    {
      value: false,
      label: t('common.total')
    }
  ];

  const maxLength = 200;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8}>
        <FormControl id="field">
          <FormLabel>{t('common.title')}</FormLabel>
          <Input
            {...register('field', { required: true })}
            placeholder={t('placeholder.enter_field')}
            defaultValue={defaultValues?.field}
            autoFocus
          />
        </FormControl>
        <FormControl id="help_text">
          <FormLabel>{t('common.help_text')}</FormLabel>
          <CharacterCounterInput
            isTextArea
            useControllerProps={{
              name: 'help_text',
              control: control,
              rules: {
                required: false,
                maxLength: {
                  value: helpTextMaxLength,
                  message: t('common.character_limit_error', {
                    limit: helpTextMaxLength
                  })
                }
              }
            }}
            placeholder={t('placeholder.enter_field')}
            defaultValue={defaultValues?.help_text}
          />
        </FormControl>
        {addDefaultOptions && (
          <>
            <FormControl id="options">
              <FormLabel>{t('common.options')}</FormLabel>
              <Stack spacing={4}>
                {options.map((field, index) => (
                  <HStack key={field.id} alignItems="flex-start">
                    <CharacterCounterInput
                      useControllerProps={{
                        name: `options.${index}.option`,
                        control: control,
                        rules: {
                          required: true,
                          maxLength: {
                            value: maxLength,
                            message: t('common.character_limit_error', {
                              limit: maxLength
                            })
                          }
                        },
                        defaultValue: defaultValues?.field
                      }}
                    />
                    <Button
                      variant="outline"
                      isDisabled={index < 2}
                      onClick={() => remove(index)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </HStack>
                ))}
              </Stack>
            </FormControl>
            <Button
              alignSelf="flex-start"
              variant="link"
              onClick={() => append({ option: '' })}
            >
              {t('workflow.add_option')}
            </Button>
          </>
        )}
        <FormControl id="step">
          <FormLabel>{t('common.step')}</FormLabel>
          <LoadingWrapper
            statuses={[stepsStatus]}
            errorMessages={[
              t('common.could_not_fetch_data_please_try_again_later', {
                data: t('common.fields').toLowerCase()
              })
            ]}
          >
            {stepsIsSuccess ? (
              <Controller
                name="step"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={stepOptions}
                    placeholder={`${t('common.select')} ${t(
                      'common.step'
                    ).toLowerCase()}`}
                    getOptionLabel={step => step.label}
                    getOptionValue={step => step}
                    onChange={value => {
                      field.onChange(value);
                    }}
                    useBasicStyles
                    defaultValue={
                      defaultValues?.step
                        ? stepOptions.find(
                            step => step.id === defaultValues.step.id
                          )
                        : defaultValues?.form
                          ? stepOptions[0]
                          : stepOptions[1]
                    }
                  />
                )}
              />
            ) : null}
          </LoadingWrapper>
        </FormControl>
        {(stepWatch?.id || stepWatch?.form === false) && (
          <FormControl id="scorer">
            <FormLabel>{t('template.who_can_see_and_answer')}</FormLabel>
            <Controller
              name="scorer"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder={t('common.select')}
                  options={[
                    {
                      id: 'COL',
                      label: t('common.collection_managers')
                    },
                    {
                      id: 'CON',
                      label: t('common.contributors')
                    },
                    {
                      id: 'EVE',
                      label: t('common.everyone')
                    }
                  ]}
                  getOptionLabel={scorer => scorer.label}
                  getOptionValue={scorer => scorer.id}
                  onChange={value => {
                    field.onChange(value);
                  }}
                  useBasicStyles
                  defaultValue={defaultValues?.scorer}
                />
              )}
            />
          </FormControl>
        )}
        {typeof defaultValues?.average === 'boolean' && (
          <FormControl id="average">
            <FormLabel>{t('chart.show_result_as')}</FormLabel>
            <Controller
              name="average"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder={t('common.select')}
                  options={averageOptions}
                  onChange={value => {
                    field.onChange(value);
                  }}
                  useBasicStyles
                  defaultValue={averageOptions[defaultValues.average ? 0 : 1]}
                />
              )}
            />
          </FormControl>
        )}
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default FieldForm;
