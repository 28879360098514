import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Stack,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import AnnouncementDetailed from './AnnouncementDetailed';
import AnnouncementListItem from './AnnouncementListItem';
import AnnouncementForm from './AnnouncementForm';
import ModalWrapper from 'components/ModalWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import api from 'utils/api';

const AnnouncementList = () => {
  const { slug } = useParams();
  const { data: profile } = useQuery(['profile', slug]);
  const { data: hive } = useQuery(['hive', slug]);

  const {
    isOpen: showAnnouncementModal,
    onOpen: openAnnouncementModal,
    onClose: closeAnnouncementModal
  } = useDisclosure();

  const {
    isOpen: showCreateAnnouncementModal,
    onOpen: openCreateAnnouncementModal,
    onClose: closeCreateAnnouncementModal
  } = useDisclosure();

  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  const { t } = useTranslation();
  const toast = useToast();

  const queryClient = useQueryClient();

  const {
    status,
    data: announcements,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isSuccess
  } = useInfiniteQuery(
    ['announcements', slug],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/announcements?offset=${pageParam}&limit=10`
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  const handleOnCreateAnnouncement = async announcement => {
    const payload = new FormData();
    payload.append('hive', hive.id);
    payload.append('image', announcement.image ? announcement.image : '');
    payload.append(
      'unsplash',
      announcement.unsplash ? announcement.unsplash : ''
    );
    payload.append('title', announcement.title);
    payload.append(
      'description',
      announcement.description ? announcement.description : ''
    );
    try {
      const {
        data: { id }
      } = await api.post('/announcements', payload, { timeout: 0 });
      if (announcement.video instanceof File) {
        const { data } = await api.post('/signed-url', {
          filename: announcement.video.name,
          model_type: 'announcement',
          model_id: id
        });
        await axios.put(data.signed_url, announcement.video, {
          headers: {
            'Content-Type':
              announcement.video.type || 'application/octet-stream'
          }
        });
        await api.patch(`/announcements/${id}`, { video: data.key });
      }
      queryClient.invalidateQueries(['announcements', slug]);
      toast({
        title: t('toast.create_success', {
          entity: t('common.announcement')
        }),
        status: 'success'
      });
    } catch (e) {
      toast({
        title: t('toast.create_error', {
          entity: t('common.announcement')
        }),
        status: 'error'
      });
    } finally {
      closeCreateAnnouncementModal();
    }
  };

  return (
    <Container maxW="container.lg" marginY={8}>
      <Stack spacing={8}>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading fontSize="2xl">{t('common.announcements')}</Heading>
          {profile?.is_admin_or_manager && (
            <Button colorScheme="teal" onClick={openCreateAnnouncementModal}>
              <HStack>
                <FontAwesomeIcon icon={faPlus} />
              </HStack>
            </Button>
          )}
        </Flex>
        <LoadingWrapper
          statuses={[status]}
          errorMessages={[
            t('common.could_not_fetch_data_please_try_again_later', {
              data: t('common.announcements').toLowerCase()
            })
          ]}
        >
          {isSuccess ? (
            <>
              <InfiniteScrollHelper
                hasMore={!isFetching && hasNextPage}
                loadMore={fetchNextPage}
              >
                <Stack spacing={4}>
                  {announcements.pages.map(page =>
                    page.results.map(announcement => (
                      <AnnouncementListItem
                        key={announcement.id}
                        announcement={announcement}
                        onClickReadMore={() => {
                          setSelectedAnnouncement(announcement);
                          openAnnouncementModal();
                        }}
                      />
                    ))
                  )}
                  {isFetching && <LoadingIndicator />}
                </Stack>
              </InfiniteScrollHelper>
              {selectedAnnouncement && (
                <AnnouncementDetailed
                  announcement={selectedAnnouncement}
                  canEdit={profile?.is_admin_or_manager}
                  isOpen={showAnnouncementModal}
                  onClose={closeAnnouncementModal}
                />
              )}
              <ModalWrapper
                title={t('home.create_announcement')}
                size="full"
                isOpen={showCreateAnnouncementModal}
                onClose={closeCreateAnnouncementModal}
              >
                <AnnouncementForm
                  isOpen={showCreateAnnouncementModal}
                  onSubmit={handleOnCreateAnnouncement}
                />
              </ModalWrapper>
            </>
          ) : null}
        </LoadingWrapper>
      </Stack>
    </Container>
  );
};

export default AnnouncementList;
