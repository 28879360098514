import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Flex, FormLabel, Stack } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';

const RoleForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid }
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  const roleOptions = [
    { id: 'ADMIN', value: t('common.admin') },
    { id: 'MANAGER', value: t('common.manager') },
    { id: 'MEMBER', value: t('common.member') },
    { id: 'GUEST', value: t('common.guest') }
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <FormLabel>{t('common.role')}</FormLabel>
          <Controller
            name="role"
            defaultValue={
              defaultValues.role
                ? roleOptions.find(
                    roleOption => roleOption.id === defaultValues.role
                  )
                : roleOptions[0]
            }
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                placeholder={t('common.select')}
                options={roleOptions}
                getOptionLabel={role => role.value}
                getOptionValue={role => role.id}
                useBasicStyles
              />
            )}
          />
        </Stack>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid || !isDirty}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default RoleForm;
