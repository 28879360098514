import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Accordion,
  Avatar,
  Button,
  Container,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  ActionMenu,
  EditMenuItem,
  DeleteMenuItem
} from 'components/ActionMenu';
import BackButton from 'components/BackButton';
import MembersButton from 'components/MembersButton';
import ModalWrapper from 'components/ModalWrapper';
import LoadingWrapper from 'components/LoadingWrapper';
import OwnerModal from 'components/modals/OwnerModal';
import TitleDescription from 'components/TitleDescription';
import GridAttachmentList from 'features/attachment/GridAttachmentList';
import CollectionCreationForm from 'features/collection/CollectionCreationForm';
import CollectionStep from 'features/collection/CollectionStep';
import api from 'utils/api';
import ProjectStep from './step/ProjectStep';
import ProjectForm from './ProjectForm';
import AttachmentUploadComponent from 'features/attachment/AttachmentUploadComponent';

const ProjectDetailed = () => {
  const [newCollection, setNewCollection] = useState(null);
  const [activeStepIndex, setActiveStepIndex] = useState(null);
  const [activeStep, setActiveStep] = useState(null);

  const {
    isOpen: showDeleteProjectModal,
    onOpen: openDeleteProjectModal,
    onClose: closeDeleteProjectModal
  } = useDisclosure();

  const {
    isOpen: showUpdateProjectModal,
    onOpen: openUpdateProjectModal,
    onClose: closeUpdateProjectModal
  } = useDisclosure();

  const {
    isOpen: showChangeOwnerModal,
    onOpen: openChangeOwnerModal,
    onClose: closeChangeOwnerModal
  } = useDisclosure();

  const {
    isOpen: showCreateCollectionModal,
    onOpen: openCreateCollectionModal,
    onClose: closeCreateCollectionModal
  } = useDisclosure();

  const navigate = useNavigate();
  const { slug, projectId } = useParams();
  const { data: profile } = useQuery(['profile', slug]);

  const toast = useToast();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    status,
    data: project,
    isSuccess
  } = useQuery(
    ['project', projectId],
    async () => {
      const { data } = await api.get(`/projects/${projectId}`);
      return data;
    },
    {
      onSuccess: project => {
        if (!activeStep) {
          setActiveStep(
            project.template && project.template.steps.length > 0
              ? project.template.steps[0]
              : null
          );
        }
      }
    }
  );

  const updateProjectMutation = useMutation(
    project => api.patch(`/projects/${projectId}`, project),
    {
      onSuccess: ({ data }) => {
        closeUpdateProjectModal();
        queryClient.setQueryData(['project', projectId], data);
        toast({
          title: t('toast.update_success', {
            entity: t('common.project')
          }),
          status: 'success'
        });
      },
      onError: () => {
        toast({
          title: t('toast.update_error', {
            entity: t('common.project')
          }),
          status: 'error'
        });
      }
    }
  );

  const deleteProjectMutation = useMutation(
    () => api.delete(`/projects/${projectId}`),
    {
      onSuccess: () => {
        closeDeleteProjectModal();
        toast({
          title: t('toast.delete_success', {
            entity: t('common.project')
          }),
          status: 'success'
        });
        navigate(`/${slug}/channel/${project.channel.id}`);
      },
      onError: () => {
        toast({
          title: t('toast.delete_error', {
            entity: t('common.project')
          }),
          status: 'error'
        });
      }
    }
  );

  const handleOnUpdateProject = async project => {
    await updateProjectMutation.mutateAsync({
      ...project,
      channel: project.channel ? project.channel.id : null,
      template: project.template ? project.template.id : null
    });
  };

  const handleOnClickDelete = async () => {
    await deleteProjectMutation.mutateAsync();
  };

  const handleOnSubmitMembers = async ({ members }) => {
    await updateProjectMutation.mutateAsync({
      members: members.map(member => member.id)
    });
  };

  const handleOnSubmitOwner = async owner => {
    await updateProjectMutation.mutateAsync({ owner: owner.id });
  };

  const handleOnCloseCreateCollectionModal = () => {
    closeCreateCollectionModal();
    if (newCollection) {
      navigate(`/${slug}/collection/${newCollection.id}?mode=preview`);
    }
  };

  return (
    <Container maxW="container.lg" marginY={8}>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.project').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <>
            <Stack spacing={4}>
              <Flex alignItems="center">
                <Text variant="muted" minW={20}>
                  {t('common.channel')}
                </Text>
                <Flex
                  justifyContent={['flex-end', null, 'flex-start']}
                  width="full"
                >
                  <Button
                    as={Link}
                    to={`/channel/${project.channel.id}`}
                    variant="link"
                  >
                    <Text maxW="20ch" textOverflow="ellipsis" overflow="hidden">
                      {project.channel.title}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
              <Stack spacing={8}>
                <Flex alignItems="center" justifyContent="space-between">
                  <BackButton />
                  <HStack spacing={4}>
                    <Stack
                      direction={['column', 'row', 'row']}
                      alignItems="center"
                    >
                      <Text variant="muted">{t('common.owner')}</Text>
                      <Button
                        variant="outline"
                        onClick={openChangeOwnerModal}
                        px={2}
                      >
                        <Avatar
                          mx={1}
                          size="sm"
                          key={project.owner.id}
                          src={
                            project.owner?.avatar
                              ? project.owner.avatar
                              : project.owner?.third_party_avatar
                                ? project.owner.third_party_avatar
                                : null
                          }
                          name={project.owner?.full_name}
                        />
                      </Button>
                    </Stack>
                    <Stack
                      alignItems="center"
                      direction={['column', 'row', 'row']}
                    >
                      <Text variant="muted">{t('common.members')}</Text>
                      <MembersButton
                        max={3}
                        members={project.members}
                        onSubmit={handleOnSubmitMembers}
                        modalTitle={t('common.members')}
                        canEdit={profile?.is_admin_or_manager}
                      />
                    </Stack>
                  </HStack>
                </Flex>
              </Stack>
              <Stack spacing={8}>
                <Stack spacing={4}>
                  <TitleDescription
                    title={project.title}
                    description={project.description}
                    button={
                      <ActionMenu>
                        <EditMenuItem onClick={openUpdateProjectModal} />
                        <DeleteMenuItem onClick={openDeleteProjectModal} />
                        {profile?.is_admin_or_manager && (
                          <AttachmentUploadComponent
                            modelConfig={{
                              singular: 'project',
                              plural: 'projects',
                              modelId: projectId
                            }}
                          />
                        )}
                      </ActionMenu>
                    }
                  />
                  <GridAttachmentList
                    modelConfig={{
                      singular: 'project',
                      plural: 'projects',
                      modelId: projectId
                    }}
                    canEdit
                  />
                  <Accordion
                    index={activeStepIndex}
                    onChange={stepIndex => {
                      setActiveStepIndex(stepIndex);
                    }}
                    allowToggle
                    borderColor="rgba(1, 1, 1, 0)"
                  >
                    {project.template?.steps.length > 0 ? (
                      project.template?.steps.map((step, index) => {
                        const metric = project.metrics.collections.steps.find(
                          metric => metric.step === step.id
                        );
                        return (
                          <ProjectStep
                            key={step.id}
                            step={step}
                            stepNumber={index + 1}
                            projectId={projectId}
                            noOfCollections={metric ? metric.count : 0}
                            onClickCreateCollection={step => {
                              setActiveStep(step);
                              openCreateCollectionModal();
                            }}
                          />
                        );
                      })
                    ) : (
                      <CollectionStep
                        projectId={projectId}
                        onClickCreateCollection={() => {
                          setActiveStep(null);
                          openCreateCollectionModal();
                        }}
                      />
                    )}
                  </Accordion>
                </Stack>
              </Stack>
              <ModalWrapper
                title={t('common.project')}
                isOpen={showUpdateProjectModal}
                onClose={closeUpdateProjectModal}
              >
                <ProjectForm
                  isOpen={showUpdateProjectModal}
                  defaultValues={{
                    channel: project.channel,
                    title: project.title,
                    description: project.description,
                    active: project.active,
                    template: project.template
                  }}
                  onSubmit={handleOnUpdateProject}
                />
              </ModalWrapper>
            </Stack>
            <ModalWrapper
              size="full"
              isOpen={showCreateCollectionModal}
              onClose={handleOnCloseCreateCollectionModal}
            >
              <CollectionCreationForm
                isOpen={showCreateCollectionModal}
                onClose={handleOnCloseCreateCollectionModal}
                onCollectionCreated={newCollection => {
                  setNewCollection(newCollection);
                }}
                newCollection={newCollection}
              />
            </ModalWrapper>
            <ConfirmationModal
              deleteText={t(
                'confirmation.all_information_in_this_project_will_be_permanently_removed_and_cannot_be_restored'
              )}
              isOpen={showDeleteProjectModal}
              onClose={closeDeleteProjectModal}
              onDelete={handleOnClickDelete}
            />
            <OwnerModal
              canEdit={profile?.is_admin_or_manager}
              members={project.members}
              owner={project.owner}
              isOpen={showChangeOwnerModal}
              onClose={closeChangeOwnerModal}
              onChangeOwner={handleOnSubmitOwner}
            />
          </>
        ) : null}
      </LoadingWrapper>
    </Container>
  );
};

export default ProjectDetailed;
