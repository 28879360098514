import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Center,
  Container,
  Heading,
  Image,
  Stack,
  useColorModeValue
} from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import HiveList from 'features/hive/HiveList';
import { useAuth } from 'providers/AuthProvider';
import api from 'utils/api';
import { useUi } from 'providers/UiProvider';

const JoinPage = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { inTeams } = useUi();

  const {
    status: connectedStatus,
    data: connectedHives,
    isSuccess: connectedHivesIsSuccess
  } = useQuery(
    ['hives', 'connected'],
    async () => {
      const { data } = await api.get(`/hives?group=connected`);
      return data;
    },
    {
      onError: () => {}
    }
  );

  const {
    status: recommendedStatus,
    data: recommendedHives,
    isSuccess: recommendedHivesIsSuccess
  } = useQuery(
    ['hives', 'recommended'],
    async () => {
      const { data } = await api.get(`/hives?group=recommended`);
      return data;
    },
    {
      onError: () => {}
    }
  );

  useEffect(() => {
    async function fetchMe() {
      try {
        await api.get('/users/me');
        const slug = localStorage.getItem('slug');
        if (slug) {
          navigate(`/${slug}`);
        }
      } catch (e) {
        logout();
        navigate('/');
      }
    }
    fetchMe();
  }, [logout, navigate]);

  return (
    <Container maxW="container.lg">
      <Center py={10}>
        <Image
          src={useColorModeValue('/hives_dark.png', '/hives_light.png')}
          maxHeight="40px"
        />
      </Center>
      <LoadingWrapper
        statuses={[connectedStatus, recommendedStatus]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.hives').toLowerCase()
          })
        ]}
      >
        <Stack spacing={8}>
          {connectedHivesIsSuccess && recommendedHivesIsSuccess ? (
            connectedHives.length > 0 || recommendedHives.length > 0 ? (
              <>
                {connectedHives.length > 0 && (
                  <HiveList
                    hives={connectedHives}
                    title={t('common.connected')}
                  />
                )}
                {recommendedHives.length > 0 && (
                  <HiveList
                    hives={recommendedHives}
                    title={t('common.recommended')}
                  />
                )}
              </>
            ) : (
              <Container height="full" maxW="container.xs">
                <Stack py={8} spacing={8} alignItems="center">
                  <Heading textAlign="left" fontSize="xl">
                    {t('login.no_hives_found')}
                  </Heading>
                  <Button
                    variant="link"
                    as={Link}
                    to={`/hives/${inTeams ? 'create' : 'register'}`}
                  >
                    {t('login.click_here_to_register')}
                  </Button>
                </Stack>
              </Container>
            )
          ) : null}
        </Stack>
      </LoadingWrapper>
    </Container>
  );
};

export default JoinPage;
