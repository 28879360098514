import { Link, useParams } from 'react-router-dom';
import {
  AspectRatio,
  Card as ChakraCard,
  CardBody as ChakraCardBody,
  Heading,
  Stack,
  Text
} from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';
import { getCollectingPlural, getCollectingSingular } from './collectionUtils';

const CollectionListItem = ({ collection, onClick }) => {
  const { slug } = useParams();

  return (
    <>
      <ChakraCard
        variant="outline"
        cursor="pointer"
        direction="row"
        overflow="hidden"
        as={Link}
        to={
          onClick
            ? null
            : {
                pathname: `/${slug}/collection/${collection.id}`
              }
        }
        onClick={onClick ? onClick : () => {}}
      >
        <AspectRatio width={['80px', '120px', null]} ratio={16 / 9}>
          <CoverMedia object={collection} rounded={null} />
        </AspectRatio>
        <ChakraCardBody minHeight="100px">
          <Stack spacing={4} justifyContent="space-between" height="full">
            <Heading fontSize="md" noOfLines={3} wordBreak="break-word">
              {collection.title}
            </Heading>
            <Text fontSize="xs" textTransform="lowercase">
              {`${collection.metrics.cards} ${
                collection.metrics.cards === 1
                  ? getCollectingSingular(collection.type)
                  : getCollectingPlural(collection.type)
              }`}
            </Text>
          </Stack>
        </ChakraCardBody>
      </ChakraCard>
      {/* <ItemWrapper
        ref={ref}
        direction="row"
        to={
          onClick
            ? null
            : {
                pathname: `/${slug}/collection/${collection.id}`
              }
        }
        onClick={onClick ? onClick : () => {}}
      >
        <HStack spacing={4}>
          <CoverMedia
            object={collection}
            height={['100px']}
            minW={['80px', '120px', null]}
            maxW={['80px', '120px', null]}
          />
        </HStack>
      </ItemWrapper> */}
    </>
  );
};

export default CollectionListItem;
