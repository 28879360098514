import { Stack } from '@chakra-ui/react';
import BoxContainer from './BoxContainer';

const ItemWrapper = ({ onClick, to, children, ref, ...rest }) => (
  <BoxContainer innerRef={ref} to={to} onClick={onClick} cursor="pointer">
    <Stack {...rest}>{children}</Stack>
  </BoxContainer>
);

export default ItemWrapper;
