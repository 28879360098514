import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Select } from 'chakra-react-select';
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import api from 'utils/api';
import Description from 'components/Description';

const GroupModal = () => {
  const { slug } = useParams();
  const { data: me } = useQuery('me');
  const { data: profile } = useQuery(['profile', slug]);
  const { data: hive } = useQuery(['hive', slug]);
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { status, data: groups } = useQuery(['groups', slug], async () => {
    const { data } = await api.get(`/groups?hive__slug=${slug}`);
    return data;
  });

  const { data: hints, isSuccess: hintsIsSuccess } = useQuery(
    'hints',
    async () => {
      const { data } = await api.get('/hints');
      return data;
    }
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange'
  });

  const updateProfileMutation = useMutation(
    payload => api.patch(`/profiles/${profile.id}`, payload),
    {
      onSuccess: ({ data: profile }) => {
        queryClient.setQueryData(['profile', slug], profile);
      }
    }
  );

  const handleOnSubmitGroup = async ({ group }) => {
    try {
      await updateProfileMutation.mutateAsync({
        group: group ? group.id : null
      });
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  const groupHint = hintsIsSuccess ? hints.find(h => h.type === 'GROUP') : null;

  if (
    status === 'success' &&
    groups?.length > 0 &&
    me &&
    !profile.group &&
    hive?.force_group_selection
  ) {
    return (
      <Modal isOpen={true} size="lg">
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(handleOnSubmitGroup)}>
            <ModalHeader>
              {groupHint ? groupHint.title : t('group.select_group')}
            </ModalHeader>
            <ModalBody>
              <FormControl id="group">
                <FormLabel>
                  <Description
                    children={
                      groupHint ? groupHint.description : t('common.group')
                    }
                  />
                </FormLabel>
                <Controller
                  name="group"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={groups}
                      placeholder={t('placeholder.search')}
                      getOptionLabel={group => group.name}
                      getOptionValue={group => group.id}
                      useBasicStyles
                    />
                  )}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                colorScheme="teal"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              >
                {t('button.save')}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    );
  }
  return null;
};

export default GroupModal;
