import { Link, Text } from '@chakra-ui/react';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import ReactMarkdown from 'react-markdown';

const Description = ({ noOfLines, children }) => (
  <ReactMarkdown
    components={{
      ...ChakraUIRenderer(),
      p: props => <Text noOfLines={noOfLines} {...props} />,
      a: props => {
        if (props.href.startsWith('mailto')) {
          return <Link href={props.href}>{props.children}</Link>;
        }
        return (
          <Link href={props.href} target="_blank" rel="noopener noreferrer">
            {props.children}
          </Link>
        );
      }
    }}
    children={children}
    skipHtml
  />
);

export default Description;
