import { useMemo } from 'react';
import { useSortBy, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useCard } from 'providers/CardProvider';
import {
  Box,
  Button,
  HStack,
  Table,
  Tbody,
  Text,
  Td,
  Th,
  Thead,
  Tr,
  Stack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/pro-regular-svg-icons';
import TagPopover from 'features/tag/TagPopover';
import { sortCardTableByTags } from './cardUtils';

const CardTable = ({ cards, filter, onClick, onChangeTags }) => {
  const { openCard } = useCard();
  const { t } = useTranslation();

  const sortTags = useMemo(() => {
    return sortCardTableByTags;
  }, []);

  const data = useMemo(() => {
    const temp = [];
    cards?.pages?.forEach(page =>
      page?.results?.forEach(card => {
        temp.push({
          id: card.id,
          title: card.title,
          views: card.metrics.views,
          votes: card.metrics.votes,
          tags: card.tags.sort((a, b) => a.name.localeCompare(b.name))
        });
      })
    );
    return temp;
  }, [cards]);
  const columns = useMemo(
    () => [
      {
        Header: t('common.title'),
        accessor: 'title',
        textAlign: 'left',
        sortType: 'string',
        pl: 0
      },
      {
        Header: t('common.tags'),
        accessor: 'tags',
        textAlign: 'left',
        sortType: sortTags
      },
      {
        Header: t('common.views'),
        accessor: 'views',
        textAlign: 'center',
        sortType: 'alphanumeric'
      },
      {
        Header: t('common.votes'),
        accessor: 'votes',
        textAlign: 'right',
        sortType: 'alphanumeric'
      }
    ],
    [sortTags, t]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ data, columns }, useSortBy);
  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map(headerGroup => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                textAlign={column.textAlign}
                pl={column?.pl}
              >
                <HStack display="inline-flex" spacing={4}>
                  <Text userSelect="none">{column.render('Header')}</Text>
                  <Box as="span">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          aria-label="sorted descending"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          aria-label="sorted ascending"
                        />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} />
                    )}
                  </Box>
                </HStack>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <Td
                  {...cell.getCellProps()}
                  isNumeric={cell.column.isNumeric}
                  textAlign={cell.column.textAlign}
                  pl={cell.column?.pl}
                >
                  {cell.column.id === 'title' ? (
                    <Stack alignItems="flex-start">
                      <Button
                        variant="link"
                        onClick={() => {
                          if (onClick) {
                            onClick(cell.row.original);
                          } else {
                            openCard(cell.row.original.id);
                          }
                        }}
                      >
                        <Text
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          maxW={['160px', null, '320px']}
                        >
                          {cell.render('Cell')}
                        </Text>
                      </Button>
                    </Stack>
                  ) : cell.column.id === 'tags' ? (
                    <Box width={['120px', null, '200px']}>
                      <TagPopover
                        cardId={cell.row.original.id}
                        cardTags={cell.row.original.tags}
                        collectionId={filter.collection}
                        onChangeTags={onChangeTags}
                      />
                    </Box>
                  ) : (
                    cell.render('Cell')
                  )}
                </Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
export default CardTable;
