import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  AspectRatio,
  Button,
  HStack,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react';
import { ActionMenu, CustomMenuItem, DeleteMenuItem } from './ActionMenu';
import CoverMedia from './CoverMedia';
import LoadingWrapper from './LoadingWrapper';
import UnsplashModal from './modals/UnsplashModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-duotone-svg-icons';
import {
  faEllipsisVertical,
  faSearch,
  faPlayCircle
} from '@fortawesome/pro-regular-svg-icons';
import api from 'utils/api';
import { findMedia } from 'utils/media';
import IconTooltip from './tooltips/IconTooltip';

const ImageSelect = ({
  object,
  preloadImage = false,
  setValue,
  uploadVideo = false,
  isDiscrete = false
}) => {
  const { code, slug } = useParams();
  const imageRef = useRef();
  const videoRef = useRef();
  const [showUnsplashModal, setShowUnsplashModal] = useState(false);

  const { t } = useTranslation();
  const toast = useToast();

  const {
    data: placeholderImages,
    status,
    isSuccess
  } = useQuery(['placeholder-images'], async () => {
    let params = new URLSearchParams();
    params.append('hive__slug', slug);
    if (code) {
      params.append('code', code);
    }
    const { data } = await api.get(`/placeholder-images?${params.toString()}`);
    return data;
  });

  useEffect(() => {
    if (isSuccess && preloadImage) {
      const fetchImage = async () => {
        try {
          const UNSPLASH_COLLECTION_ID = 3330448;
          const { data } = await axios.get(
            `https://api.unsplash.com/photos/random?client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}&collections=${UNSPLASH_COLLECTION_ID}`
          );
          setValue('unsplash', data.urls.regular);
        } catch (error) {
          console.error('Error fetching Unsplash image:', error);
        }
      };
      if (placeholderImages?.length > 0) {
        const placeholderImage =
          placeholderImages[
            Math.floor(Math.random() * placeholderImages.length)
          ];
        setValue('placeholder_image', placeholderImage.id);
      } else {
        fetchImage();
      }
    }
  }, [isSuccess, preloadImage, placeholderImages, setValue]);

  const toggleUnsplashModal = () => setShowUnsplashModal(prev => !prev);

  const handleRemoveMedia = () => {
    setValue('image', null);
    setValue('unsplash', null);
    setValue('placeholder_image', null);
    if (uploadVideo) setValue('video', null);
  };

  const handleUploadImage = e => {
    const image = e.target.files[0];
    if (!image) return;
    setValue('image', image);
    setValue('unsplash', null);
    setValue('placeholder_image', null);
    if (uploadVideo) setValue('video', null);

    e.target.value = ''; // Reset the input value
  };

  const handleUploadVideo = e => {
    const video = e.target.files[0];
    if (!video) return;

    if (video.size > 1000 * 1024 * 1024) {
      toast({
        title: t('toast.video_size_error'),
        status: 'error'
      });
      e.target.value = ''; // Reset the input value
      return;
    }

    if (!video.name.match(/\.(mp4|webm)$/i)) {
      toast({
        title: t('toast.video_format_error'),
        status: 'error'
      });
      e.target.value = ''; // Reset the input value
      return;
    }

    setValue('image', null);
    setValue('unsplash', null);
    setValue('placeholder_image', null);
    setValue('video', video);

    e.target.value = ''; // Reset the input value
  };

  const handleUploadUnsplash = url => {
    setValue('image', null);
    setValue('unsplash', url);
    setValue('placeholder_image', null);
    if (uploadVideo) setValue('video', null);
  };

  const placeholderImage = placeholderImages?.find(
    image => image.id === object.placeholder_image
  );

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.images').toLowerCase()
        })
      ]}
    >
      {isSuccess && (
        <Stack position="relative">
          {placeholderImage || !isDiscrete || findMedia(object) ? (
            placeholderImage ? (
              <AspectRatio ratio={16 / 9}>
                <Image src={placeholderImage.thumbnail_image} />
              </AspectRatio>
            ) : (
              <AspectRatio ratio={16 / 9}>
                <CoverMedia object={object} clickToFullscreen />
              </AspectRatio>
            )
          ) : (
            <Menu>
              <MenuButton alignSelf="flex-start" as={Button} variant="outline">
                <HStack>
                  <FontAwesomeIcon icon={faImage} />
                  <Text>{t('button.add_media')}</Text>
                </HStack>
              </MenuButton>
              <MenuList>
                {uploadVideo && (
                  <CustomMenuItem
                    icon={faPlayCircle}
                    label={t('button.upload_video')}
                    onClick={() => videoRef.current.click()}
                    command={
                      <IconTooltip
                        label={t('common.video_format_disclaimer')}
                      />
                    }
                  />
                )}
                <CustomMenuItem
                  icon={faImage}
                  label={t('button.upload_image')}
                  onClick={() => imageRef.current.click()}
                />
                {placeholderImages?.length === 0 && (
                  <CustomMenuItem
                    icon={faSearch}
                    label={t('button.search_image')}
                    onClick={toggleUnsplashModal}
                  />
                )}
              </MenuList>
            </Menu>
          )}
          {(!isDiscrete || findMedia(object)) && (
            <ActionMenu
              icon={faEllipsisVertical}
              variant="overlay"
              size="sm"
              position="absolute"
              top={2}
              right={2}
            >
              {findMedia(object) && (
                <DeleteMenuItem
                  label={t('button.remove')}
                  onClick={handleRemoveMedia}
                />
              )}
              {uploadVideo && (
                <CustomMenuItem
                  icon={faPlayCircle}
                  label={t('button.upload_video')}
                  onClick={() => videoRef.current.click()}
                  command={
                    <IconTooltip label={t('common.video_format_disclaimer')} />
                  }
                />
              )}
              <CustomMenuItem
                icon={faImage}
                label={t('button.upload_image')}
                onClick={() => imageRef.current.click()}
              />
              {placeholderImages?.length === 0 && (
                <CustomMenuItem
                  icon={faSearch}
                  label={t('button.search_image')}
                  onClick={toggleUnsplashModal}
                />
              )}
            </ActionMenu>
          )}
          <Input
            type="file"
            accept="video/mp4, video/webm"
            ref={videoRef}
            onChange={handleUploadVideo}
            hidden
          />
          <Input
            type="file"
            accept="image/*"
            ref={imageRef}
            onChange={handleUploadImage}
            hidden
          />
          <UnsplashModal
            isOpen={showUnsplashModal}
            onClose={toggleUnsplashModal}
            onImage={handleUploadUnsplash}
          />
        </Stack>
      )}
    </LoadingWrapper>
  );
};

export default ImageSelect;
