import { Box, keyframes, useColorModeValue, useTheme } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const ButtonAnimator = ({
  active = false,
  borderRadius = '5px',
  colorScheme = 'teal',
  children,
  ...rest
}) => {
  const theme = useTheme();
  const bgColor = useColorModeValue(
    theme.colors[colorScheme]['500'],
    theme.colors[colorScheme]['200']
  );
  const animationKeyframes = keyframes`
    0% { box-shadow: 0 0 0 0 ${bgColor + '70'}; border-radius:${borderRadius}; }
    45% { box-shadow: 0 0 0 30px ${
      bgColor + '00'
    }; border-radius:${borderRadius};}
    50%, 100% { box-shadow: 0 0 0 0 ${
      bgColor + '00'
    }; border-radius:${borderRadius};}
  `;

  const animation = `${animationKeyframes} 3s 3s ease-in-out 3`;
  return (
    <Box as={motion.div} animation={active ? animation : null} {...rest}>
      {children}
    </Box>
  );
};

export default ButtonAnimator;
