import { Box, HStack, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMegaphone } from '@fortawesome/pro-regular-svg-icons';
import ItemWrapper from 'components/ItemWrapper';
import { useRef } from 'react';

const AnnouncementListItem = ({ announcement, onClickReadMore }) => {
  const ref = useRef();
  return (
    <ItemWrapper
      ref={ref}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={onClickReadMore}
    >
      <HStack spacing={4}>
        <Box color="yellow.400">
          <FontAwesomeIcon icon={faMegaphone} />
        </Box>
        <Text>{announcement.title}</Text>
      </HStack>
    </ItemWrapper>
  );
};

export default AnnouncementListItem;
