import { useTranslation } from 'react-i18next';
import ActionMenu, {
  CustomMenuItem,
  EditMenuItem
} from 'components/ActionMenu';
import { faPlus, faSortUpDown } from '@fortawesome/pro-solid-svg-icons';
import { MenuDivider, useDisclosure, useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from 'utils/api';
import { NavLink, useParams } from 'react-router-dom';
import ModalWrapper from 'components/ModalWrapper';
import AccessForm from 'components/AccessForm';

const HomeActionMenu = ({ onClickHome }) => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);

  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  const {
    isOpen: showCreateBoardModal,
    onOpen: openCreateBoardModal,
    onClose: closeCreateBoardModal
  } = useDisclosure();

  const { data: boards } = useQuery(
    ['boards', slug],
    async () => {
      const { data } = await api.get(`/boards?hive=${hive.id}`);
      return data;
    },
    { enabled: false }
  );

  const createBoardMutation = useMutation(
    board => api.post('/boards', board, { timeout: 0 }),
    {
      onSuccess: ({ data: board }) => {
        closeCreateBoardModal();
        toast({
          title: t('toast.create_success', {
            entity: t('common.board')
          }),
          status: 'success'
        });
        queryClient.setQueryData(['boards', slug], [board, ...boards]);
      },
      onError: () => {
        toast({
          title: t('toast.create_error', {
            entity: t('common.board')
          }),
          status: 'error'
        });
      }
    }
  );

  const handleOnCreateBoard = async board => {
    const payload = new FormData();
    payload.append('hive', hive.id);
    payload.append('image', board.image ? board.image : '');
    payload.append('unsplash', board.unsplash ? board.unsplash : '');
    payload.append('title', board.title);
    payload.append('description', board.description ? board.description : '');
    payload.append('access', board.access.id);
    if (board.domains) {
      board.domains.forEach(domain => {
        payload.append('domains', domain.id);
      });
    }
    if (board.groups) {
      board.groups.forEach(group => {
        payload.append('groups', group.id);
      });
    }
    if (board.members) {
      board.members.forEach(member => {
        payload.append('members', member.id);
      });
    }
    await createBoardMutation.mutateAsync(payload);
  };

  return (
    <ActionMenu>
      <EditMenuItem
        label={t('home.edit_welcome_message')}
        onClick={onClickHome}
      />
      <MenuDivider />
      <CustomMenuItem
        icon={faPlus}
        label={t('board.new_board')}
        onClick={openCreateBoardModal}
      />
      <CustomMenuItem
        as={NavLink}
        to={`/${slug}/board-order`}
        icon={faSortUpDown}
        label={t('board.board_order')}
      />
      <ModalWrapper
        title={t('board.create_board')}
        size="full"
        isOpen={showCreateBoardModal}
        onClose={closeCreateBoardModal}
      >
        <AccessForm
          isOpen={showCreateBoardModal}
          onSubmit={handleOnCreateBoard}
          showImageSelect
        />
      </ModalWrapper>
    </ActionMenu>
  );
};

export default HomeActionMenu;
