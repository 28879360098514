import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ItemWrapper from 'components/ItemWrapper';
import { Badge, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { useRef } from 'react';

const ChannelListItem = ({ channel }) => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const ref = useRef();

  return (
    <ItemWrapper ref={ref} to={`/${slug}/channel/${channel.id}`}>
      <Stack spacing={4}>
        <Flex>
          <Badge>{t('common.channel')}</Badge>
        </Flex>
        <Stack>
          <Heading fontSize="md" noOfLines={2}>
            {channel.title}
          </Heading>
          <Text noOfLines={2} fontSize="sm">
            {channel.description}
          </Text>
        </Stack>
      </Stack>
    </ItemWrapper>
  );
};

export default ChannelListItem;
