import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  Stack
} from '@chakra-ui/react';
import ChartResult from 'features/chart/ChartResult';
import api from 'utils/api';
import LoadingWrapper from 'components/LoadingWrapper';

const AverageScoresPerChart = ({ filter, active }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    data: fields,
    status,
    isSuccess,
    refetch: fetchCharts
  } = useQuery(
    ['charts', filter],
    async () => {
      const params = new URLSearchParams();
      if (filter?.workflow) {
        params.append('workflow', filter.workflow);
      }
      if (filter?.collection) {
        params.append('collection', filter.collection);
      }
      params.append('type', 'RATING');
      const { data } = await api.get(`/fields?${params.toString()}`);
      return data;
    },
    {
      enabled: active
    }
  );

  useEffect(() => {
    queryClient.removeQueries('charts');
    fetchCharts();
  }, [fetchCharts, queryClient, filter]);

  return (
    <LoadingWrapper statuses={[status]}>
      {isSuccess ? (
        <>
          {fields.length > 0 ? (
            <Tabs
              size="sm"
              variant="fresh"
              index={tabIndex}
              onChange={tabIndex => {
                setTabIndex(tabIndex);
              }}
            >
              <TabList overflowX="auto" maxWidth="full">
                {fields?.map(field => (
                  <Tab whiteSpace="nowrap" key={field.id}>
                    {field.field}
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                {fields?.map(field => (
                  <TabPanel key={field.id} py={4}>
                    <Stack spacing={4}>
                      <ChartResult
                        chart={field.chart}
                        filter={{
                          collection: filter.collection,
                          step: filter.step,
                          tags: filter.tags
                        }}
                      />
                    </Stack>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          ) : (
            <Text variant="muted">{t('common.nothing_here_yet')}</Text>
          )}
        </>
      ) : null}
    </LoadingWrapper>
  );
};
export default AverageScoresPerChart;
