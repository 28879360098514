import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Flex,
  FormControl,
  FormHelperText,
  HStack,
  Input,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import CopyLinkButton from 'components/CopyLinkButton';
import QrCodeButton from 'components/QrCodeButton';
import SpinnerSwitch from 'components/SpinnerSwitch';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import api from 'utils/api';

const ShareSurvey = ({ survey }) => {
  const { slug } = useParams();

  const queryClient = useQueryClient();

  const {
    isOpen: showDeleteLinkModal,
    onClose: closeDeleteLinkModal,
    onOpen: openDeleteLinkModal
  } = useDisclosure();

  const createLinkMutation = useMutation(
    () => api.post(`/surveys/${survey?.id}/share?hive__slug=${slug}`),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(['survey', survey.id.toString()], data);
      }
    }
  );

  const deleteLinkMutation = useMutation(
    () => api.delete(`/surveys/${survey.id}/share?hive__slug=${slug}`),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(['survey', survey.id.toString()], data);
        closeDeleteLinkModal();
      }
    }
  );

  const { t } = useTranslation();

  const handleOnCreateLink = async () => {
    await createLinkMutation.mutateAsync();
  };

  const handleOnDeleteLink = async () => {
    await deleteLinkMutation.mutateAsync();
  };

  const url = `${process.env.REACT_APP_URL}/${slug}/shared/survey/${survey?.code}`;

  return (
    <>
      <Stack spacing={8}>
        <Stack spacing={4}>
          <Flex alignItems="center" justifyContent="space-between">
            <Text variant="muted">{t('common.activate_link')}</Text>
            <SpinnerSwitch
              isChecked={survey?.code ? true : false}
              optionKey="code"
              onChange={() => {
                if (survey?.code) {
                  openDeleteLinkModal();
                } else {
                  handleOnCreateLink();
                }
              }}
            />
          </Flex>
          <FormControl>
            {survey?.code && (
              <HStack>
                <Input value={url} readOnly />{' '}
                <CopyLinkButton
                  variant="solid"
                  colorScheme="blue"
                  icon={faCopy}
                  label={t('button.copy')}
                  url={url}
                />
              </HStack>
            )}
            <FormHelperText whiteSpace="pre-line">
              {survey?.code
                ? t('survey.activate_link_on_explainer')
                : t('survey.activate_link_off_explainer')}
            </FormHelperText>
          </FormControl>
        </Stack>
        {survey?.code && (
          <QrCodeButton
            value={url}
            filename="link.svg"
            explainer={t('common.share_link_on_qr_code_explainer')}
          />
        )}
      </Stack>
      <ConfirmationModal
        deleteHeader={t('common.deactivate_link')}
        deleteText={t('common.deactivate_link_warning')}
        buttonText={t('common.deactivate')}
        isOpen={showDeleteLinkModal}
        onClose={closeDeleteLinkModal}
        onDelete={() => {
          handleOnDeleteLink();
        }}
      />
    </>
  );
};

export default ShareSurvey;
