import { useMemo } from 'react';
import { useSortBy, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useCard } from 'providers/CardProvider';
import IconTooltip from 'components/tooltips/IconTooltip';
import { formatScore } from 'features/chart/chartUtils';
import {
  Box,
  Button,
  HStack,
  Table,
  Tbody,
  Text,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Tooltip,
  TableContainer
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/pro-regular-svg-icons';
import ScorersButton from 'features/card/ScorersButton';
import { sortCardTableByTags } from 'features/card/cardUtils';
import TagPopover from 'features/tag/TagPopover';
import { useParams } from 'react-router-dom';

const ScoreTable = ({
  chart,
  results,
  scores,
  wideHeaders = false,
  onChangeTags
}) => {
  const { openCard } = useCard();
  const { t } = useTranslation();
  const { collectionId } = useParams();

  const sortTags = useMemo(() => {
    return sortCardTableByTags;
  }, []);

  const sortMembers = useMemo(
    () => (rowA, rowB, columnId) => {
      if (rowA?.values?.[columnId].length > rowB?.values?.[columnId].length)
        return 1;
      if (rowB?.values?.[columnId].length > rowA?.values?.[columnId].length)
        return -1;
      return 0;
    },
    []
  );

  const data = useMemo(() => {
    if (results && scores) {
      const items = [];
      results.forEach(card => {
        let item = {
          id: card.id,
          title: card.title,
          tags: card.tags,
          scorers: scores
            .filter(score => score.card === card.id)
            .reduce((scorers, current) => {
              if (!scorers.find(member => member.id === current.creator.id)) {
                return [...scorers, current.creator];
              } else {
                return scorers;
              }
            }, [])
        };
        if (chart.average) {
          card.average_score.forEach((score, index) => {
            item[`param_${index}`] = formatScore(score);
          });
          const left = formatScore(
            card.average_score.reduce((a, b) => a + b, 0)
          );
          const right = formatScore(card.max_score);
          item['total'] = `${left} / ${right}`;
        } else {
          card.total_score.forEach((score, index) => {
            item[`param_${index}`] = formatScore(score);
          });
        }
        items.push(item);
      });
      return items;
    }
    return [];
  }, [results, chart, scores]);

  const columns = useMemo(() => {
    const items = [];
    items.push({
      Header: t('common.title'),
      accessor: 'title',
      sortType: 'string',
      textAlign: 'left',
      borderRightWidth: 1,
      pr: 4,
      pl: 0
    });
    items.push({
      Header: t('common.tags'),
      accessor: 'tags',
      sortType: sortTags,
      textAlign: 'left',
      borderRightWidth: 1,
      pr: 4,
      pl: 4
    });
    if (chart) {
      if (chart.average) {
        items.push({
          Header: t('common.total'),
          accessor: 'total',
          borderRightWidth: 1,
          sortType: 'alphanumeric',
          isNumeric: true,
          textAlign: 'left',
          pr: 4,
          pl: 4
        });
      }
      items.push({
        Header: t('card.scored_by'),
        accessor: 'scorers',
        sortType: sortMembers,
        textAlign: 'left',
        borderRightWidth: 1,
        pl: 4,
        pr: 4,
        wordBreak: 'normal',
        whiteSpace: 'nowrap'
      });
      chart.parameters.forEach((parameter, index) => {
        items.push({
          Header: parameter.label,
          Footer: parameter.description && (
            <IconTooltip label={parameter.description} />
          ),
          accessor: `param_${index}`,
          sortType: 'alphanumeric',
          textAlign: 'left',
          borderRightWidth: 1,
          pl: 4,
          pr: 4,
          minWidth: wideHeaders ? '10ch' : null,
          maxWidth: wideHeaders ? null : '20ch',
          wordBreak: wideHeaders ? 'break-word' : null,
          whiteSpace: wideHeaders ? 'normal' : 'nowrap'
        });
      });
    }
    return items;
  }, [t, sortTags, chart, sortMembers, wideHeaders]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ data, columns }, useSortBy);
  return (
    <TableContainer>
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()} borderBottom="none">
              {headerGroup.headers.map(column => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  borderRightWidth={column.borderRightWidth}
                  textAlign={column.textAlign}
                  justifyContent="flex-start"
                  pl={column?.pl}
                  pr={column?.pr}
                  bg={column?.bg}
                >
                  <HStack
                    minWidth={column?.minWidth}
                    maxWidth={column?.maxWidth}
                    display="inline-flex"
                    justifyContent="flex-start"
                    spacing={2}
                  >
                    <>
                      {column.id.startsWith('param') && column.Footer && (
                        <Box>{column.render('Footer')}</Box>
                      )}
                      <Tooltip
                        isDisabled={column?.disableSortBy === true}
                        label={column.render('Header')}
                        hasArrow
                        placement="top"
                      >
                        <Box
                          textOverflow={wideHeaders ? null : 'ellipsis'}
                          noOfLines={wideHeaders ? 99 : 1}
                          wordBreak={column?.wordBreak}
                          whiteSpace={column?.whiteSpace}
                          display="block"
                          userSelect="none"
                        >
                          {column.render('Header')}
                        </Box>
                      </Tooltip>
                    </>
                    {!column.disableSortBy && (
                      <Box as="span">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon
                              icon={faSortDown}
                              aria-label="sorted descending"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faSortUp}
                              aria-label="sorted ascending"
                            />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </Box>
                    )}
                  </HStack>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Td
                    {...cell.getCellProps()}
                    isNumeric={cell.column.isNumeric}
                    textAlign={cell.column.textAlign}
                    borderRightWidth={cell.column?.borderRightWidth}
                    pl={cell.column?.pl}
                    pr={cell.column?.pr}
                    borderBottom="none"
                  >
                    {cell.column.id === 'title' ? (
                      <Stack alignItems="flex-start">
                        <Button
                          variant="link"
                          onClick={() => {
                            openCard(cell.row.original.id);
                          }}
                        >
                          <Text
                            textAlign="left"
                            whiteSpace="normal"
                            overflow="hidden"
                            noOfLines={2}
                            width={['120px', null, '200px']}
                          >
                            {cell.render('Cell')}
                          </Text>
                        </Button>
                      </Stack>
                    ) : cell.column.id === 'tags' ? (
                      <TagPopover
                        cardId={cell.row.original.id}
                        cardTags={cell.row.original.tags}
                        collectionId={collectionId}
                        onChangeTags={onChangeTags}
                      />
                    ) : cell.column.id === 'total' ? (
                      <Text whiteSpace="nowrap">{cell.render('Cell')}</Text>
                    ) : cell.column.id === 'scorers' ? (
                      <Stack alignItems="flex-start">
                        <ScorersButton
                          scores={scores.filter(
                            score => score.card === cell.row.original.id
                          )}
                          parameters={chart.parameters}
                          isAdminOrCollectionManager={true}
                        />
                      </Stack>
                    ) : (
                      <Text
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        maxW={['15ch', null, '25ch']}
                      >
                        {cell.render('Cell')}
                      </Text>
                    )}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
export default ScoreTable;
