import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Flex, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import CharacterCounterInput from 'components/CharacterCounterInput';
import { Select } from 'chakra-react-select';
import i18next from 'i18next';

const completionOption = {
  id: 'completion',
  field: `${i18next.t('survey.complete_survey')} 🎉`
};

const OptionForm = ({
  defaultValues,
  isOpen,
  onSubmit,
  fields,
  selectedField
}) => {
  const { t } = useTranslation();

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting, isValid },
    watch
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    let nextField = null;
    if (defaultValues?.is_completion) {
      nextField = completionOption;
    } else {
      nextField = fields?.find(f => f.id === defaultValues.next_field);
    }
    reset({ ...defaultValues, next_field: nextField });
  }, [reset, defaultValues, fields, isOpen]);

  const maxLength = 200;
  const { option } = watch();

  /* Get fields after the current field */
  const currentFieldIndex = fields?.findIndex(f => f.id === selectedField.id);
  const fieldsAfterCurrent = fields?.slice(currentFieldIndex + 1);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="option">
          <FormLabel>{t('common.option')}</FormLabel>
          <CharacterCounterInput
            useControllerProps={{
              name: 'option',
              control: control,
              rules: {
                maxLength: {
                  value: maxLength,
                  message: t('common.character_limit_error', {
                    limit: maxLength
                  })
                }
              },
              defaultValue: defaultValues?.option ? defaultValues.option : ''
            }}
          />
        </FormControl>
        {selectedField?.type === 'SINGLE_SELECT' &&
          fields &&
          fields.length > 0 && (
            <Controller
              name="next_field"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <FormLabel>{t('survey.next_field')}</FormLabel>
                  <Select
                    {...field}
                    options={[
                      ...fieldsAfterCurrent.map(field => ({
                        id: field.id,
                        field: field.field
                      })),
                      completionOption
                    ]}
                    getOptionLabel={f => f.field}
                    getOptionValue={f => f.id}
                    useBasicStyles
                    isClearable
                  />
                </FormControl>
              )}
            />
          )}
        <Flex justifyContent="flex-end">
          <Button
            isDisabled={option?.length < 1 || !isValid}
            type="submit"
            colorScheme="teal"
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default OptionForm;
