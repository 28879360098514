import { Button, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ContributionAccessForm = ({ defaultValues, onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    defaultValues
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="4">
        <Controller
          control={control}
          name="contribution_access"
          render={({ field: { onChange, value } }) => (
            <RadioGroup value={value} onChange={onChange}>
              <Stack spacing="4">
                <Radio value="CONTRIBUTORS">{t('common.contributors')}</Radio>
                <Radio value="MANAGERS">
                  {t('common.collection_managers')}
                </Radio>
                <Radio value="CLOSED">{t('common.closed')}</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
        <Button
          alignSelf="flex-end"
          colorScheme="teal"
          isLoading={isSubmitting}
          type="submit"
        >
          {t('button.save')}
        </Button>
      </Stack>
    </form>
  );
};

export default ContributionAccessForm;
