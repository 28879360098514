import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import ItemWrapper from 'components/ItemWrapper';
import { Link } from 'react-router-dom';
import { useRef } from 'react';

const HiveListItem = ({ hive }) => {
  const { t } = useTranslation();
  const ref = useRef();

  const image = useColorModeValue(
    hive.dark_logo ? hive.dark_logo : '/hives_dark.png',
    hive.light_logo ? hive.dark_logo : '/hives_light.png'
  );

  return (
    <ItemWrapper ref={ref}>
      <Stack spacing={4}>
        <Flex
          alignItems="center"
          justifyContent="center"
          bg={useColorModeValue('gray.50', 'whiteAlpha.100')}
          height="120px"
          rounded="md"
        >
          <Image
            src={image}
            maxHeight="120px"
            maxWidth="120px"
            objectFit="contain"
            rounded="md"
          />
        </Flex>
        <Heading size="sm">{hive.name}</Heading>
        <Button colorScheme="teal" as={Link} to={`/${hive.slug}`}>
          <HStack>
            <Text>{t('button.open')}</Text>
            <FontAwesomeIcon icon={faArrowRight} />
          </HStack>
        </Button>
      </Stack>
    </ItemWrapper>
  );
};

export default HiveListItem;
