import { Reorder } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import {
  Button,
  Stack,
  Table,
  Tbody,
  Td,
  Tr,
  useToast,
  useDisclosure,
  Thead,
  Th,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDots, faPlus } from '@fortawesome/pro-solid-svg-icons';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import ActionMenu, {
  DeleteMenuItem,
  EditMenuItem
} from 'components/ActionMenu';
import ModalWrapper from 'components/ModalWrapper';
import { getFieldTypeLabel } from 'features/workflow/workflowUtils';
import api from 'utils/api';
import SurveyFieldForm from './SurveyFieldForm';
import OptionTable from 'features/workflow/OptionTable';
import axios from 'axios';

let timer = null;

const SurveyFieldTable = ({ surveyId, fields, filter, survey }) => {
  const [isMultiSelect, setIsMultiSelect] = useState(false);

  const [selectedField, setSelectedField] = useState();

  const {
    isOpen: showCreateSelectFieldModal,
    onOpen: openCreateSelectFieldModal,
    onClose: closeCreateSelectFieldModal
  } = useDisclosure();

  const {
    isOpen: showCreateFieldModal,
    onOpen: openCreateFieldModal,
    onClose: closeCreateFieldModal
  } = useDisclosure();

  const {
    isOpen: showDeleteFieldModal,
    onOpen: openDeleteFieldModal,
    onClose: closeDeleteFieldModal
  } = useDisclosure();

  const {
    isOpen: showUpdateFieldModal,
    onOpen: openUpdateFieldModal,
    onClose: closeUpdateFieldModal
  } = useDisclosure();

  const toast = useToast();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const createFieldMutation = useMutation(
    field => api.post('/surveys/fields', field),
    {
      onSuccess: ({ data: field }) => {
        closeCreateFieldModal();
        closeCreateSelectFieldModal();
        toast({
          title: t('toast.create_success', {
            entity: t('common.field')
          }),
          status: 'success'
        });
        queryClient.setQueryData(
          ['survey', surveyId, 'fields', filter],
          [...fields, field]
        );
      },
      onError: () => {
        toast({
          title: t('toast.create_error', {
            entity: t('common.field')
          }),
          status: 'error'
        });
      }
    }
  );

  const deleteFieldMutation = useMutation(
    fieldId => api.delete(`/surveys/fields/${fieldId}`),
    {
      onSuccess: (_, fieldId) => {
        closeDeleteFieldModal();
        toast({
          title: t('toast.delete_success', {
            entity: t('common.field')
          }),
          status: 'success'
        });
        queryClient.setQueryData(
          ['survey', surveyId, 'fields', filter],
          fields => fields.filter(f => f.id !== fieldId)
        );
      }
    }
  );

  const updateFieldMutation = useMutation(
    field => api.patch(`/surveys/fields/${selectedField.id}`, field),
    {
      onSuccess: ({ data: field }) => {
        closeUpdateFieldModal();
        toast({
          title: t('toast.update_success', {
            entity: t('common.field')
          }),
          status: 'success'
        });
        queryClient.setQueryData(
          ['survey', surveyId, 'fields', filter],
          fields => fields.map(f => (f.id === field.id ? field : f))
        );
      },
      onError: () => {
        toast({
          title: t('toast.update_error', {
            entity: t('common.field')
          }),
          status: 'error'
        });
      }
    }
  );

  const handleOnClickDelete = async () => {
    await deleteFieldMutation.mutateAsync(selectedField.id);
  };

  const handleOnCreateField = async (field, type = 'INPUT') => {
    await createFieldMutation.mutateAsync({
      ...field,
      survey: survey.id,
      options: field.options ? field.options.map(({ option }) => option) : [],
      type
    });
  };

  const handleOnUpdateField = async field => {
    const payload = new FormData();

    if (!field.image || field.image instanceof File) {
      payload.append('image', field.image ? field.image : '');
    }
    if (!field.video) {
      payload.append('video', '');
    }
    payload.append('unsplash', field.unsplash ? field.unsplash : '');

    payload.append('field', field.field);
    payload.append('required', field.required);

    try {
      if (field.video instanceof File) {
        const { data } = await api.post('/s3/generate-presigned-url', {
          filename: field.video.name
        });
        await axios.put(data.url, field.video, {
          headers: { 'x-amz-acl': 'public-read' }
        });
        payload.append('video', data.key);
      }
    } catch (e) {
      console.log(e);
    }
    await updateFieldMutation.mutateAsync(payload);
  };

  return (
    <Stack spacing={4}>
      <Reorder.Group
        axis="y"
        values={fields}
        onReorder={fields => {
          if (timer != null) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => {
            try {
              const fields = queryClient.getQueryData([
                'survey',
                surveyId,
                'fields',
                filter
              ]);
              api.patch(`/surveys/${surveyId}/reorder-fields`, {
                fields: fields.map(field => field.id)
              });
            } catch (e) {
              console.log(e);
            }
          }, 1000);
          queryClient.setQueryData(
            ['survey', surveyId, 'fields', filter],
            fields
          );
        }}
      >
        <Table variant="unstyled">
          <Thead>
            <Tr>
              <Th pl={0}>{t('common.field')}</Th>
              <Th textAlign="center">{t('common.type')}</Th>
              <Th pr={0} textAlign="right">
                {t('common.edit')}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {fields.map(field => (
              <Reorder.Item as="tr" key={field.id} value={field}>
                <Td pl={0}>
                  <HStack>
                    <Text cursor="grab" variant="light">
                      <FontAwesomeIcon icon={faGripDots} />
                    </Text>
                    <Button
                      variant="link"
                      onClick={() => {
                        setSelectedField(field);
                        openUpdateFieldModal();
                      }}
                    >
                      {field.field}
                    </Button>
                  </HStack>
                </Td>
                <Td textAlign="center">{getFieldTypeLabel(field)}</Td>
                <Td pr={0} textAlign="right">
                  <ActionMenu>
                    <EditMenuItem
                      onClick={() => {
                        setSelectedField(field);
                        openUpdateFieldModal();
                      }}
                    />
                    <DeleteMenuItem
                      onClick={() => {
                        setSelectedField(field);
                        openDeleteFieldModal();
                      }}
                    />
                  </ActionMenu>
                </Td>
              </Reorder.Item>
            ))}
          </Tbody>
        </Table>
      </Reorder.Group>
      <Menu>
        <MenuButton
          alignSelf="flex-start"
          as={Button}
          variant="outline"
          size="sm"
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          {t('common.add_question')}
        </MenuButton>
        <MenuList>
          <MenuItem onClick={openCreateFieldModal}>
            {t('common.text_field')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsMultiSelect(true);
              openCreateSelectFieldModal();
            }}
          >
            {t('common.multi_select_field')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsMultiSelect(false);
              openCreateSelectFieldModal();
            }}
          >
            {t('common.single_select_field')}
          </MenuItem>
          {/* <MenuItem onClick={openCreateFieldModal}>
            {t('common.rating')}
          </MenuItem> */}
        </MenuList>
      </Menu>
      <ModalWrapper
        title={t('template.create_field')}
        size="full"
        isOpen={showCreateFieldModal}
        onClose={closeCreateFieldModal}
      >
        <SurveyFieldForm
          onSubmit={async values => await handleOnCreateField(values, 'INPUT')}
        />
      </ModalWrapper>
      <ModalWrapper
        title={getFieldTypeLabel(selectedField)}
        isOpen={showUpdateFieldModal}
        onClose={closeUpdateFieldModal}
        size="2xl"
      >
        <Stack>
          <SurveyFieldForm
            defaultValues={{
              image: selectedField?.image,
              unsplash: selectedField?.unsplash,
              video: selectedField?.video,
              field: selectedField?.field,
              required: selectedField?.required
            }}
            onSubmit={handleOnUpdateField}
          />
          {selectedField?.type !== 'INPUT' && <Divider />}
          {(selectedField?.type === 'MULTI_SELECT' ||
            selectedField?.type === 'SINGLE_SELECT') && (
            <OptionTable
              fieldId={selectedField?.id}
              fields={fields}
              baseUrl="/surveys/options"
              selectedField={selectedField}
            />
          )}
        </Stack>
      </ModalWrapper>
      <ModalWrapper
        title={
          isMultiSelect
            ? t('template.create_multi_select_field')
            : t('template.create_single_select_field')
        }
        size="full"
        isOpen={showCreateSelectFieldModal}
        onClose={closeCreateSelectFieldModal}
      >
        <SurveyFieldForm
          onSubmit={async values => {
            await handleOnCreateField(
              values,
              isMultiSelect ? 'MULTI_SELECT' : 'SINGLE_SELECT'
            );
          }}
          addDefaultOptions
        />
      </ModalWrapper>
      <ConfirmationModal
        deleteText={t(
          'confirmation.this_data_will_be_permanently_removed_and_cannot_be_restored',
          { data: t('common.field').toLowerCase() }
        )}
        isOpen={showDeleteFieldModal}
        onClose={closeDeleteFieldModal}
        onDelete={handleOnClickDelete}
      />
    </Stack>
  );
};

export default SurveyFieldTable;
